import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-primary/90 backdrop-blur-sm border-t border-accent/20 h-12">
      <div className="max-w-7xl mx-auto px-4 h-full flex items-center justify-center">
        <div className="text-sm text-gray-300/80">
          © {new Date().getFullYear()} Tutor Buddy. All rights reserved.
        </div>
      </div>
    </footer>
  );
}