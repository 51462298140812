import OpenAI from 'openai';
import { z } from 'zod';
import { Note } from '../components/notes/types';

export const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const responseSchema = z.object({
  content: z.string(),
  role: z.string(),
});

let conversationHistory: { role: string; content: string }[] = [];

function formatNotesContext(notes: Note[]): string {
  if (notes.length === 0) return 'No relevant notes found.';
  
  return notes
    .map(note => `Note (${note.tags.join(', ')}): ${note.content}`)
    .join('\n\n');
}

export function clearConversationHistory() {
  conversationHistory = [];
}

export async function getChatResponse(message: string, relevantNotes: Note[] = []) {
  try {
    if (!message.trim()) {
      throw new Error('Please provide a question or topic to discuss.');
    }

    const notesContext = formatNotesContext(relevantNotes);
    const systemPrompt = `You are Tutor Buddy, a friendly and knowledgeable AI tutor. Your responses should be comprehensive and detailed.

Student's relevant notes:
${notesContext}

Instructions:
- Provide detailed, thorough responses to questions
- Reference and build upon the student's notes when relevant
- If their notes contain related information, mention it explicitly
- If their notes have gaps or misconceptions, address them gently
- Keep responses clear and educational
- Use examples to illustrate complex concepts
- When appropriate, suggest topics for future note-taking
- For follow-up questions, refer to previous context when relevant
- Aim to provide complete explanations rather than brief answers

Remember: Your goal is to help the student connect new information with their existing knowledge while providing comprehensive responses.`;

    conversationHistory.push({ role: "user", content: message });

    if (conversationHistory.length > 10) {
      conversationHistory = conversationHistory.slice(-10);
    }

    const completion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: systemPrompt },
        ...conversationHistory
      ],
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_tokens: 2048,
      presence_penalty: 0.2,
      frequency_penalty: 0.3,
    });

    const response = responseSchema.parse(completion.choices[0].message);
    conversationHistory.push({ role: "assistant", content: response.content });

    return response.content;
  } catch (error) {
    if (error instanceof z.ZodError) {
      throw new Error('Received invalid response format from AI service.');
    }
    
    if (error instanceof Error) {
      throw error;
    }
    
    throw new Error('Failed to get AI response. Please try again.');
  }
}