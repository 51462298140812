import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Bot, Send } from 'lucide-react';
import { toast } from 'sonner';
import VoiceWidget from '../VoiceWidget';
import ChatMessage from '../ChatMessage';
import { Note } from '../notes/types';
import { useSpeechSynthesis } from '../../hooks/useSpeechSynthesis';
import { getChatResponse } from '../../lib/openai';

interface Message {
  id: string;
  text: string;
  isAI: boolean;
}

interface TutorSectionProps {
  notes: Note[];
}

const WELCOME_MESSAGE: Message = {
  id: '1',
  text: "Hi! I'm your AI tutor. I can help you with your studies and I have access to your notes. Feel free to ask me questions about any topic, including your saved notes!",
  isAI: true
};

export default function TutorSection({ notes }: TutorSectionProps) {
  const [messages, setMessages] = useState<Message[]>([WELCOME_MESSAGE]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { speak } = useSpeechSynthesis();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Auto-resize textarea
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [currentMessage]);

  const findRelevantNotes = (query: string): Note[] => {
    if (!notes.length) return [];
    
    const searchTerms = query.toLowerCase().split(' ');
    const relevantNotes = notes.filter(note => {
      const noteContent = note.content.toLowerCase();
      const noteTags = note.tags.map(tag => tag.toLowerCase());
      
      return searchTerms.some(term => 
        noteContent.includes(term) || 
        noteTags.some(tag => tag.includes(term))
      );
    });

    return relevantNotes.sort((a, b) => {
      const aMatches = searchTerms.filter(term => 
        a.content.toLowerCase().includes(term) || 
        a.tags.some(tag => tag.toLowerCase().includes(term))
      ).length;
      
      const bMatches = searchTerms.filter(term => 
        b.content.toLowerCase().includes(term) || 
        b.tags.some(tag => tag.toLowerCase().includes(term))
      ).length;
      
      return bMatches - aMatches;
    }).slice(0, 5);
  };

  const handleSend = async () => {
    if (!currentMessage.trim() || isProcessing) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      text: currentMessage.trim(),
      isAI: false
    };

    setMessages(prev => [...prev, userMessage]);
    setCurrentMessage('');
    setIsProcessing(true);

    try {
      const relevantNotes = findRelevantNotes(currentMessage);
      const response = await getChatResponse(currentMessage, relevantNotes);
      
      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: response,
        isAI: true
      };

      setMessages(prev => [...prev, aiMessage]);
      
      // Mobile-specific speech handling
      if (isMobile) {
        // Request audio focus for mobile devices
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        await audioContext.resume();
        
        // Use shorter chunks for mobile speech
        speak(response);
      } else {
        speak(response);
      }
    } catch (error) {
      toast.error('Failed to get response. Please try again.');
    } finally {
      setIsProcessing(false);
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl sm:rounded-2xl shadow-lg p-3 sm:p-4 md:p-6 border border-gray-700/50">
      <div className="flex items-center gap-2 mb-4">
        <div className="p-2 bg-indigo-500/20 rounded-lg">
          <Bot className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-400" />
        </div>
        <h2 className="text-lg sm:text-xl font-semibold text-gray-200">AI Tutor</h2>
      </div>

      <div 
        id="tutor-content" 
        className="space-y-4 mb-4 max-h-[400px] overflow-y-auto overscroll-contain"
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        {messages.map((message) => (
          <ChatMessage
            key={message.id}
            message={message.text}
            isAI={message.isAI}
          />
        ))}
      </div>

      <div className="flex flex-col sm:flex-row items-center gap-4">
        <div className="w-full relative">
          <textarea
            ref={inputRef}
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Ask me anything about your notes or any topic..."
            className="w-full px-4 py-3 pr-12 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none min-h-[44px] max-h-[120px]"
            style={{ 
              fontSize: isMobile ? '16px' : 'inherit',
              lineHeight: '1.5'
            }}
            rows={1}
            disabled={isProcessing}
          />
          <button
            onClick={handleSend}
            disabled={!currentMessage.trim() || isProcessing}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-indigo-400 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>

        <div className="w-full sm:w-auto">
          <VoiceWidget
            onSpeechResult={(text) => {
              if (text.trim()) {
                setCurrentMessage(text);
                inputRef.current?.focus();
              }
            }}
            isProcessing={isProcessing}
          />
        </div>
      </div>
    </div>
  );
}