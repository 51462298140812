import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PencilLine, Sparkles, ChevronDown, ChevronUp, Search } from 'lucide-react';
import { toast } from 'sonner';
import { Note, PRESET_TAGS } from './types';
import NoteEditor from './NoteEditor';
import NoteList from './NoteList';
import ReminderModal from './ReminderModal';
import { useAuth } from '../../contexts/AuthContext';
import { saveNote, getUserNotes, deleteNote, updateNoteReminder } from '../../lib/firebase';

interface NotesPanelProps {
  onNotesUpdate: (notes: Note[]) => void;
}

export default function NotesPanel({ onNotesUpdate }: NotesPanelProps) {
  const { currentUser } = useAuth();
  const [notes, setNotes] = useState<Note[]>([]);
  const [currentNote, setCurrentNote] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [customTag, setCustomTag] = useState('');
  const [filterTag, setFilterTag] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showTagInput, setShowTagInput] = useState(false);
  const [showNotes, setShowNotes] = useState(true);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);

  // Load notes from Firebase
  useEffect(() => {
    const loadNotes = async () => {
      if (currentUser) {
        const userNotes = await getUserNotes(currentUser.uid);
        setNotes(userNotes);
        onNotesUpdate(userNotes);
      }
    };

    loadNotes();
  }, [currentUser, onNotesUpdate]);

  const handleSaveNote = async () => {
    if (!currentUser) {
      toast.error('You must be logged in to save notes');
      return;
    }

    if (!currentNote.trim()) {
      toast.error('Please enter some content for your note');
      return;
    }

    const newNote: Note = {
      id: Date.now().toString(),
      content: currentNote,
      timestamp: Date.now(),
      tags: selectedTags,
      reminder: null,
      reminderShown: false
    };

    const success = await saveNote(currentUser.uid, newNote);
    if (success) {
      const updatedNotes = [newNote, ...notes];
      setNotes(updatedNotes);
      onNotesUpdate(updatedNotes);
      setCurrentNote('');
      setSelectedTags([]);
      toast.success('Note saved successfully');
    } else {
      toast.error('Failed to save note');
    }
  };

  const handleDeleteNote = async (id: string) => {
    if (!currentUser) return;

    const success = await deleteNote(currentUser.uid, id);
    if (success) {
      const updatedNotes = notes.filter(note => note.id !== id);
      setNotes(updatedNotes);
      onNotesUpdate(updatedNotes);
      toast.success('Note deleted successfully');
    } else {
      toast.error('Failed to delete note');
    }
  };

  const handleSetReminder = async (noteId: string, reminderDate: Date) => {
    if (!currentUser) return;

    const success = await updateNoteReminder(currentUser.uid, noteId, reminderDate);
    if (success) {
      const updatedNotes = notes.map(note =>
        note.id === noteId
          ? { ...note, reminder: reminderDate.toISOString(), reminderShown: false }
          : note
      );
      setNotes(updatedNotes);
      onNotesUpdate(updatedNotes);
      toast.success('Reminder set successfully');
    } else {
      toast.error('Failed to set reminder');
    }
  };

  const addTag = (tag: string) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags(prev => [...prev, tag]);
    }
    setCustomTag('');
    setShowTagInput(false);
  };

  const handleCustomTagSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (customTag.trim()) {
      addTag(customTag.trim());
    }
  };

  const filteredNotes = notes.filter(note => {
    const matchesTag = filterTag ? note.tags.includes(filterTag) : true;
    const matchesSearch = searchQuery
      ? note.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        note.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
      : true;
    return matchesTag && matchesSearch;
  });

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl sm:rounded-2xl shadow-lg p-3 sm:p-4 md:p-6 border border-gray-700/50">
      {/* Header */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-4 mb-4 sm:mb-6">
        <div className="flex items-center gap-2">
          <div className="p-2 bg-indigo-500/20 rounded-lg">
            <PencilLine className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-400" />
          </div>
          <h2 className="text-lg sm:text-xl font-semibold text-gray-200">Study Notes</h2>
        </div>
        
        <div className="flex items-center justify-between sm:justify-end gap-2 sm:gap-4">
          <div className="flex items-center gap-1 sm:gap-2 text-xs sm:text-sm text-gray-400">
            <Sparkles className="w-3 h-3 sm:w-4 sm:h-4" />
            {notes.length} {notes.length === 1 ? 'Note' : 'Notes'}
          </div>
          
          <button
            onClick={() => setShowNotes(!showNotes)}
            className="flex items-center gap-1 sm:gap-2 px-2 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm font-medium text-indigo-400 hover:text-indigo-300 transition-colors"
          >
            {showNotes ? (
              <>
                <ChevronUp className="w-3 h-3 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline">Hide Notes</span>
              </>
            ) : (
              <>
                <ChevronDown className="w-3 h-3 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline">View Notes</span>
              </>
            )}
          </button>
        </div>
      </div>

      {/* Search */}
      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search notes..."
            className="w-full px-4 py-2 pl-10 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
        </div>
      </div>

      {/* Note Editor */}
      <NoteEditor
        content={currentNote}
        selectedTags={selectedTags}
        showTagInput={showTagInput}
        customTag={customTag}
        presetTags={PRESET_TAGS}
        onContentChange={setCurrentNote}
        onSave={handleSaveNote}
        onAddTag={addTag}
        onRemoveTag={(tag) => setSelectedTags(prev => prev.filter(t => t !== tag))}
        onShowTagInput={() => setShowTagInput(true)}
        onCustomTagChange={setCustomTag}
        onCustomTagSubmit={handleCustomTagSubmit}
      />

      {/* Note List */}
      <AnimatePresence>
        {showNotes && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div id="notes-content">
              <NoteList
                notes={filteredNotes}
                filterTag={filterTag}
                presetTags={PRESET_TAGS}
                onFilterChange={setFilterTag}
                onDeleteNote={handleDeleteNote}
                onSetReminder={(note) => {
                  setSelectedNote(note);
                  setShowReminderModal(true);
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Reminder Modal */}
      <ReminderModal
        isOpen={showReminderModal}
        onClose={() => {
          setShowReminderModal(false);
          setSelectedNote(null);
        }}
        onSetReminder={(date) => {
          if (selectedNote) {
            handleSetReminder(selectedNote.id, date);
            setShowReminderModal(false);
            setSelectedNote(null);
          }
        }}
        note={selectedNote}
      />
    </div>
  );
}