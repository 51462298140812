import React, { useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { Upload, FileSpreadsheet } from 'lucide-react';
import { toast } from 'sonner';
import Papa from 'papaparse';

interface DataUploaderProps {
  onUpload: (data: any[], headers: string[]) => void;
}

export default function DataUploader({ onUpload }: DataUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File): boolean => {
    if (!file.name.endsWith('.csv')) {
      toast.error('Please upload a CSV file');
      return false;
    }

    if (file.size === 0) {
      toast.error('The file is empty');
      return false;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSize) {
      toast.error('File size must be less than 10MB');
      return false;
    }

    return true;
  };

  const handleFiles = (files: FileList) => {
    const file = files[0];
    if (!file) {
      toast.error('No file selected');
      return;
    }

    if (!validateFile(file)) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target?.result;
      if (typeof csvData !== 'string') {
        toast.error('Failed to read file');
        return;
      }

      if (csvData.trim().length === 0) {
        toast.error('The file is empty');
        return;
      }

      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
        transform: (value) => value.trim(),
        complete: (results) => {
          if (results.errors.length) {
            const errorMessage = results.errors
              .map(error => `Row ${error.row + 1}: ${error.message}`)
              .join('\n');
            toast.error(`Error parsing CSV file: ${errorMessage}`);
            return;
          }

          const headers = results.meta.fields || [];
          if (!headers.length) {
            toast.error('No columns found in the CSV file');
            return;
          }

          const data = results.data as any[];
          if (!data.length) {
            toast.error('No data rows found in the CSV file');
            return;
          }

          // Validate data structure
          const isValid = data.every(row => 
            headers.every(header => header in row)
          );

          if (!isValid) {
            toast.error('Invalid CSV format - some rows are missing columns');
            return;
          }

          // Clean the data
          const cleanData = data.map(row => {
            const cleanRow: any = {};
            headers.forEach(header => {
              cleanRow[header] = row[header]?.toString().trim() || '';
            });
            return cleanRow;
          });

          onUpload(cleanData, headers);
          toast.success(`Successfully loaded ${cleanData.length} rows of data`);
        },
        error: (error) => {
          console.error('CSV parsing error:', error);
          toast.error('Failed to parse CSV file: ' + error.message);
        }
      });
    };

    reader.onerror = () => {
      toast.error('Failed to read file');
    };

    reader.readAsText(file);
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  }, []);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const handleAreaClick = (e: React.MouseEvent) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-700/50 rounded-lg bg-gray-800/30 cursor-pointer"
      onClick={handleAreaClick}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <div className="p-3 bg-indigo-500/20 rounded-full mb-4">
        <FileSpreadsheet className="w-8 h-8 text-indigo-400" />
      </div>
      <h3 className="text-lg font-medium text-gray-200 mb-2">
        Upload Your Dataset
      </h3>
      <p className="text-sm text-gray-400 mb-4 text-center">
        Drag and drop your CSV file here, or click to select
      </p>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={(e) => e.target.files && handleFiles(e.target.files)}
        className="hidden"
        onClick={(e) => e.stopPropagation()}
      />
      <div className="flex flex-col items-center gap-2">
        <button 
          onClick={handleButtonClick}
          className="px-4 py-2 bg-indigo-500 text-white rounded-lg flex items-center gap-2 hover:bg-indigo-600 transition-colors"
        >
          <Upload className="w-4 h-4" />
          Select File
        </button>
        <p className="text-xs text-gray-400 mt-2">
          Maximum file size: 10MB
        </p>
      </div>
    </motion.div>
  );
}