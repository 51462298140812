import React from 'react';
import { motion } from 'framer-motion';
import { Trash2, Tag, Bell, Calendar, Share2, Printer, Copy } from 'lucide-react';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { SUBJECT_COLORS } from './types';
import type { Note } from './types';
import { shareContent, printContent, copyToClipboard } from '../../utils/sharing';

interface NoteCardProps {
  note: Note;
  onDelete: (id: string) => void;
  onSetReminder: (note: Note) => void;
}

export default function NoteCard({ note, onDelete, onSetReminder }: NoteCardProps) {
  const [isSharing, setIsSharing] = React.useState(false);

  const handleShare = async () => {
    setIsSharing(true);
    try {
      const content = `Note (${note.tags.join(', ')})\n\n${note.content}`;
      const success = await shareContent(content, 'Study Note');
      if (success) {
        toast.success('Note shared successfully');
      } else {
        const copied = await copyToClipboard(content);
        if (copied) {
          toast.success('Note copied to clipboard');
        } else {
          toast.error('Unable to share note');
        }
      }
    } catch (error) {
      toast.error('Failed to share note');
    } finally {
      setIsSharing(false);
    }
  };

  const handlePrint = () => {
    try {
      const content = `Note (${note.tags.join(', ')})\n\n${note.content}`;
      printContent(content);
      toast.success('Preparing to print note...');
    } catch (error) {
      toast.error('Failed to print note');
    }
  };

  const handleCopy = async () => {
    try {
      const content = `Note (${note.tags.join(', ')})\n\n${note.content}`;
      const success = await copyToClipboard(content);
      if (success) {
        toast.success('Note copied to clipboard');
      } else {
        toast.error('Failed to copy note');
      }
    } catch (error) {
      toast.error('Failed to copy note');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-4 bg-gray-700/30 rounded-lg relative group hover:bg-gray-700/40 transition-colors border border-gray-600/50"
    >
      <p className="text-gray-200 mb-3 leading-relaxed">{note.content}</p>
      
      <div className="flex flex-wrap gap-2 mb-3">
        {note.tags.map(tag => {
          const colors = SUBJECT_COLORS[tag as keyof typeof SUBJECT_COLORS] || {
            bg: 'bg-gray-600/30',
            text: 'text-gray-300'
          };
          
          return (
            <span
              key={tag}
              className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs ${colors.bg} ${colors.text}`}
            >
              <Tag className="w-3 h-3" />
              {tag}
            </span>
          );
        })}
      </div>

      <div className="flex items-center justify-between text-sm text-gray-400">
        <div className="flex items-center gap-4">
          <time dateTime={new Date(note.timestamp).toISOString()} className="flex items-center gap-1">
            <Calendar className="w-3 h-3" />
            {format(note.timestamp, 'MMM d, yyyy')}
          </time>
          {note.reminder && (
            <span className="flex items-center gap-1 text-indigo-400">
              <Bell className="w-3 h-3" />
              {format(new Date(note.reminder), 'MMM d, h:mm a')}
            </span>
          )}
        </div>
        
        <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={handleShare}
            disabled={isSharing}
            className="p-1 text-gray-400 hover:text-indigo-400 transition-colors"
            title="Share note"
          >
            <Share2 className="w-4 h-4" />
          </button>
          
          <button
            onClick={handlePrint}
            className="p-1 text-gray-400 hover:text-indigo-400 transition-colors"
            title="Print note"
          >
            <Printer className="w-4 h-4" />
          </button>
          
          <button
            onClick={handleCopy}
            className="p-1 text-gray-400 hover:text-indigo-400 transition-colors"
            title="Copy note"
          >
            <Copy className="w-4 h-4" />
          </button>
          
          <button
            onClick={() => onSetReminder(note)}
            className="p-1 text-gray-400 hover:text-indigo-400 transition-colors"
            title="Set reminder"
          >
            <Bell className="w-4 h-4" />
          </button>
          
          <button
            onClick={() => onDelete(note.id)}
            className="p-1 text-gray-400 hover:text-red-400 transition-colors"
            title="Delete note"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </div>
    </motion.div>
  );
}