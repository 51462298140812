import React from 'react';
import { Tag, X, Plus } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { SUBJECT_COLORS } from './types';

interface TagInputProps {
  selectedTags: string[];
  showTagInput: boolean;
  customTag: string;
  presetTags: readonly string[];
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
  onShowTagInput: () => void;
  onCustomTagChange: (value: string) => void;
  onCustomTagSubmit: (e: React.FormEvent) => void;
}

export default function TagInput({
  selectedTags,
  showTagInput,
  customTag,
  presetTags,
  onAddTag,
  onRemoveTag,
  onShowTagInput,
  onCustomTagChange,
  onCustomTagSubmit
}: TagInputProps) {
  return (
    <div className="mt-2 space-y-2">
      <div className="flex flex-wrap gap-2">
        {selectedTags.map(tag => {
          const colors = SUBJECT_COLORS[tag as keyof typeof SUBJECT_COLORS] || {
            bg: 'bg-gray-100',
            text: 'text-gray-700'
          };
          
          return (
            <span
              key={tag}
              className={`inline-flex items-center gap-1 px-2 py-1 ${colors.bg} ${colors.text} rounded-full text-sm`}
            >
              <Tag className="w-3 h-3" />
              {tag}
              <button
                onClick={() => onRemoveTag(tag)}
                className="hover:text-red-500"
              >
                <X className="w-3 h-3" />
              </button>
            </span>
          );
        })}
        {!showTagInput && (
          <button
            onClick={onShowTagInput}
            className="inline-flex items-center gap-1 px-2 py-1 bg-gray-700/30 text-gray-300 rounded-full text-sm hover:bg-gray-700/50 transition-colors"
          >
            <Plus className="w-3 h-3" />
            Add Subject
          </button>
        )}
      </div>

      <AnimatePresence>
        {showTagInput && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-2"
          >
            <div className="grid grid-cols-2 gap-2">
              {presetTags.map(tag => {
                const colors = SUBJECT_COLORS[tag as keyof typeof SUBJECT_COLORS];
                return (
                  <button
                    key={tag}
                    onClick={() => onAddTag(tag)}
                    className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                      selectedTags.includes(tag)
                        ? `${colors.bg} ${colors.text}`
                        : `${colors.bg} ${colors.text} ${colors.hover}`
                    }`}
                  >
                    {tag}
                  </button>
                );
              })}
            </div>
            <form onSubmit={onCustomTagSubmit} className="flex gap-2">
              <input
                type="text"
                value={customTag}
                onChange={(e) => onCustomTagChange(e.target.value)}
                placeholder="Custom subject..."
                className="flex-1 px-3 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-sm text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-500 text-white rounded-lg text-sm hover:bg-indigo-600 transition-colors"
              >
                Add
              </button>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}