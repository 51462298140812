import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { GraduationCap } from 'lucide-react';
import { toast } from 'sonner';
import { HomeworkAssignment, Solution } from './types';
import { generateSolution } from '../../lib/homework';
import HomeworkUploader from './HomeworkUploader';
import SolutionDisplay from './SolutionDisplay';

export default function HomeworkAssistant() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentSolution, setCurrentSolution] = useState<{
    solution: Solution;
    subject: string;
  } | null>(null);

  const handleSubmit = async (assignment: HomeworkAssignment) => {
    setIsProcessing(true);
    try {
      const solution = await generateSolution(assignment);
      setCurrentSolution({
        solution,
        subject: assignment.subject
      });
      toast.success('Solution generated successfully!');
    } catch (error) {
      console.error('Failed to generate solution:', error);
      toast.error('Failed to generate solution. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-indigo-500/20 rounded-lg">
          <GraduationCap className="w-5 h-5 text-indigo-400" />
        </div>
        <h2 className="text-xl font-semibold text-gray-200">Homework Assistant</h2>
      </div>

      <HomeworkUploader
        onSubmit={handleSubmit}
        isProcessing={isProcessing}
      />

      {currentSolution && (
        <SolutionDisplay
          solution={currentSolution.solution}
          subject={currentSolution.subject}
          onDelete={() => setCurrentSolution(null)}
        />
      )}
    </div>
  );
}