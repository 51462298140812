import { StudyPlan, StudyPreferences } from '../components/planner/types';
import { openai } from './openai';

export async function generateStudyPlan(preferences: StudyPreferences): Promise<StudyPlan> {
  try {
    const prompt = `Create a detailed study plan for ${preferences.subject} with the following preferences:
- ${preferences.dailyHours} hours per day
- ${preferences.daysPerWeek} days per week
- Difficulty level: ${preferences.difficulty}
- Learning goals: ${preferences.goals}

Please provide:
1. A brief overview of the study approach
2. A detailed schedule for one week, broken down by day, with specific activities and time allocations.

Format the response as a JSON object with:
- overview: A string with the study approach
- schedule: An array of days, where each day has an 'activities' array of strings`;

    const completion = await openai.chat.completions.create({
      messages: [
        { 
          role: "system", 
          content: "You are a professional study planner and educational expert. Create detailed, practical study plans that are realistic and achievable."
        },
        { role: "user", content: prompt }
      ],
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      response_format: { type: "json_object" },
    });

    const response = JSON.parse(completion.choices[0].message.content);

    // Validate and format the response
    const plan: StudyPlan = {
      overview: response.overview || "Personalized study plan based on your preferences.",
      schedule: response.schedule || Array(preferences.daysPerWeek).fill({ activities: [] })
    };

    return plan;
  } catch (error) {
    console.error('Error generating study plan:', error);
    throw new Error('Failed to generate study plan');
  }
}