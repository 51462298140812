import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signOut as firebaseSignOut,
  User,
  updateProfile,
  updateEmail,
  updatePassword
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  setDoc, 
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  serverTimestamp,
  Timestamp,
  orderBy
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// User Management
export const createUserDocument = async (userId: string, data: any) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Error creating user document:', error);
    throw error;
  }
};

export const isUserAdmin = async (userId: string): Promise<boolean> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() && userDoc.data()?.role === 'admin';
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

// Session Management
export const startSessionMonitoring = async (email: string) => {
  if (!email) return;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    await setDoc(sessionRef, {
      lastActive: serverTimestamp(),
      email,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error starting session monitoring:', error);
  }
};

export const stopSessionMonitoring = async (email: string) => {
  if (!email) return;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    await deleteDoc(sessionRef);
  } catch (error) {
    console.error('Error stopping session monitoring:', error);
  }
};

export const hasActiveSession = async (email: string): Promise<boolean> => {
  if (!email) return false;
  
  try {
    const sessionRef = doc(db, 'sessions', email);
    const sessionSnap = await getDoc(sessionRef);
    
    if (!sessionSnap.exists()) return false;
    
    const data = sessionSnap.data();
    const lastActive = data.lastActive?.toDate() || new Date(0);
    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
    
    return lastActive > fiveMinutesAgo;
  } catch (error) {
    console.error('Error checking session:', error);
    return false;
  }
};

// Notes Management
export const saveNote = async (userId: string, note: any) => {
  try {
    const noteRef = doc(db, `users/${userId}/notes/${note.id}`);
    await setDoc(noteRef, {
      ...note,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error saving note:', error);
    return false;
  }
};

export const getUserNotes = async (userId: string) => {
  try {
    const notesRef = collection(db, `users/${userId}/notes`);
    const q = query(notesRef, orderBy('timestamp', 'desc'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      timestamp: doc.data().timestamp?.toDate().getTime() || Date.now(),
      reminder: doc.data().reminder?.toDate().toISOString() || null
    }));
  } catch (error) {
    console.error('Error getting notes:', error);
    return [];
  }
};

export const deleteNote = async (userId: string, noteId: string) => {
  try {
    await deleteDoc(doc(db, `users/${userId}/notes/${noteId}`));
    return true;
  } catch (error) {
    console.error('Error deleting note:', error);
    return false;
  }
};

export const updateNoteReminder = async (userId: string, noteId: string, reminderDate: Date) => {
  try {
    const noteRef = doc(db, `users/${userId}/notes/${noteId}`);
    await updateDoc(noteRef, {
      reminder: Timestamp.fromDate(reminderDate),
      reminderShown: false,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error updating note reminder:', error);
    return false;
  }
};

// Study Plan Management
export const saveStudyPlan = async (userId: string, plan: any) => {
  try {
    const planRef = doc(db, `users/${userId}/studyPlans/${plan.id}`);
    await setDoc(planRef, {
      ...plan,
      updatedAt: serverTimestamp()
    });
    return plan.id;
  } catch (error) {
    console.error('Error saving study plan:', error);
    throw error;
  }
};

export const deleteStudyPlan = async (userId: string, planId: string) => {
  try {
    await deleteDoc(doc(db, `users/${userId}/studyPlans/${planId}`));
    return true;
  } catch (error) {
    console.error('Error deleting study plan:', error);
    throw error;
  }
};

// Analysis Project Management
export const saveAnalysisProject = async (userId: string, project: any) => {
  try {
    const projectRef = doc(db, `users/${userId}/analysisProjects/${project.id}`);
    await setDoc(projectRef, {
      ...project,
      updatedAt: serverTimestamp()
    });
    return project.id;
  } catch (error) {
    console.error('Error saving analysis project:', error);
    throw error;
  }
};

export const deleteAnalysisProject = async (userId: string, projectId: string) => {
  try {
    await deleteDoc(doc(db, `users/${userId}/analysisProjects/${projectId}`));
    return true;
  } catch (error) {
    console.error('Error deleting analysis project:', error);
    throw error;
  }
};

export const signOut = async () => {
  const user = auth.currentUser;
  if (user?.email) {
    await stopSessionMonitoring(user.email);
  }
  return firebaseSignOut(auth);
};

export { auth, db, storage };