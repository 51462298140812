import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Activity, User, MessageSquare, FileText, Clock } from 'lucide-react';
import { format } from 'date-fns';

interface LogEntry {
  id: string;
  userId: string;
  userName: string;
  action: string;
  type: 'chat' | 'note' | 'login' | 'signup';
  timestamp: Date;
}

export default function ActivityLog() {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logsQuery = query(
          collection(db, 'activity_logs'),
          orderBy('timestamp', 'desc'),
          limit(50)
        );
        const snapshot = await getDocs(logsQuery);
        
        const logData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate()
        })) as LogEntry[];

        setLogs(logData);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogs();
  }, []);

  const getIcon = (type: string) => {
    switch (type) {
      case 'chat':
        return <MessageSquare className="w-4 h-4" />;
      case 'note':
        return <FileText className="w-4 h-4" />;
      case 'login':
      case 'signup':
        return <User className="w-4 h-4" />;
      default:
        return <Activity className="w-4 h-4" />;
    }
  };

  const getColor = (type: string) => {
    switch (type) {
      case 'chat':
        return 'text-blue-400 bg-blue-500/20';
      case 'note':
        return 'text-green-400 bg-green-500/20';
      case 'login':
        return 'text-purple-400 bg-purple-500/20';
      case 'signup':
        return 'text-yellow-400 bg-yellow-500/20';
      default:
        return 'text-gray-400 bg-gray-500/20';
    }
  };

  if (isLoading) {
    return (
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50">
        <div className="animate-pulse space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-700/50 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50 overflow-hidden">
      <div className="p-6">
        <h3 className="text-xl font-semibold text-white mb-6">Recent Activity</h3>
        
        <div className="space-y-4">
          {logs.map((log) => (
            <div
              key={log.id}
              className="flex items-start gap-4 p-4 bg-gray-700/20 rounded-lg"
            >
              <div className={`p-2 rounded-lg ${getColor(log.type)}`}>
                {getIcon(log.type)}
              </div>
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between gap-4">
                  <p className="text-gray-200 font-medium">{log.userName}</p>
                  <div className="flex items-center gap-2 text-sm text-gray-400">
                    <Clock className="w-4 h-4" />
                    {format(log.timestamp, 'MMM d, h:mm a')}
                  </div>
                </div>
                <p className="text-gray-400 mt-1">{log.action}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}