import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { BookOpen, Brain, StickyNote, FileSpreadsheet, Calendar, GraduationCap } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import TutorSection from '../components/tutor/TutorSection';
import NotesPanel from '../components/notes/NotesPanel';
import DataAnalysisPanel from '../components/analysis/DataAnalysisPanel';
import StudyPlanGenerator from '../components/planner/StudyPlanGenerator';
import HomeworkAssistant from '../components/homework/HomeworkAssistant';
import { Note } from '../components/notes/types';

export default function Dashboard() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [notes, setNotes] = useState<Note[]>([]);
  const [activeTab, setActiveTab] = useState<'tutor' | 'notes' | 'analysis' | 'planner' | 'homework'>('tutor');

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
  }, [currentUser, navigate]);

  if (!currentUser) return null;

  const stats = [
    {
      icon: <Brain className="w-5 h-5 text-indigo-400" />,
      label: 'AI Interactions',
      value: '24/7',
      description: 'Available Support'
    },
    {
      icon: <StickyNote className="w-5 h-5 text-green-400" />,
      label: 'Study Notes',
      value: notes.length.toString(),
      description: 'Notes Created'
    },
    {
      icon: <BookOpen className="w-5 h-5 text-purple-400" />,
      label: 'Learning Progress',
      value: 'Active',
      description: 'Study Session'
    }
  ];

  return (
    <div className="flex-1 py-6 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full">
      {/* Welcome Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-8"
      >
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-100 mb-2">
          Welcome back, {currentUser?.displayName || 'Student'}!
        </h1>
        <p className="text-gray-400">
          Ready to continue your learning journey? Your AI tutor is here to help.
        </p>
      </motion.div>

      {/* Stats Overview */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8"
      >
        {stats.map((stat, index) => (
          <div
            key={index}
            className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 border border-gray-700/50"
          >
            <div className="flex items-center gap-3 mb-2">
              <div className="p-2 bg-gray-700/50 rounded-lg">
                {stat.icon}
              </div>
              <div>
                <p className="text-sm text-gray-400">{stat.label}</p>
                <p className="text-xl font-semibold text-gray-100">{stat.value}</p>
              </div>
            </div>
            <p className="text-sm text-gray-400">{stat.description}</p>
          </div>
        ))}
      </motion.div>

      {/* Tab Navigation */}
      <div className="flex gap-4 mb-6 border-b border-gray-700/50 overflow-x-auto">
        <button
          onClick={() => setActiveTab('tutor')}
          className={`px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap ${
            activeTab === 'tutor'
              ? 'text-indigo-400'
              : 'text-gray-400 hover:text-gray-300'
          }`}
        >
          AI Tutor
          {activeTab === 'tutor' && (
            <motion.div
              layoutId="activeTab"
              className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-400"
            />
          )}
        </button>
        <button
          onClick={() => setActiveTab('notes')}
          className={`px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap ${
            activeTab === 'notes'
              ? 'text-indigo-400'
              : 'text-gray-400 hover:text-gray-300'
          }`}
        >
          Study Notes
          {activeTab === 'notes' && (
            <motion.div
              layoutId="activeTab"
              className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-400"
            />
          )}
        </button>
        <button
          onClick={() => setActiveTab('homework')}
          className={`px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap ${
            activeTab === 'homework'
              ? 'text-indigo-400'
              : 'text-gray-400 hover:text-gray-300'
          }`}
        >
          Homework Help
          {activeTab === 'homework' && (
            <motion.div
              layoutId="activeTab"
              className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-400"
            />
          )}
        </button>
        <button
          onClick={() => setActiveTab('analysis')}
          className={`px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap ${
            activeTab === 'analysis'
              ? 'text-indigo-400'
              : 'text-gray-400 hover:text-gray-300'
          }`}
        >
          Data Analysis
          {activeTab === 'analysis' && (
            <motion.div
              layoutId="activeTab"
              className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-400"
            />
          )}
        </button>
        <button
          onClick={() => setActiveTab('planner')}
          className={`px-4 py-2 text-sm font-medium transition-colors relative whitespace-nowrap ${
            activeTab === 'planner'
              ? 'text-indigo-400'
              : 'text-gray-400 hover:text-gray-300'
          }`}
        >
          Study Planner
          {activeTab === 'planner' && (
            <motion.div
              layoutId="activeTab"
              className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-400"
            />
          )}
        </button>
      </div>

      {/* Main Content */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        {activeTab === 'tutor' ? (
          <TutorSection notes={notes} />
        ) : activeTab === 'notes' ? (
          <NotesPanel onNotesUpdate={setNotes} />
        ) : activeTab === 'homework' ? (
          <HomeworkAssistant />
        ) : activeTab === 'analysis' ? (
          <DataAnalysisPanel />
        ) : (
          <StudyPlanGenerator />
        )}
      </motion.div>
    </div>
  );
}