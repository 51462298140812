import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Share2, Printer, Copy, Check, Trash2, BookOpen, Link } from 'lucide-react';
import { toast } from 'sonner';
import { Solution } from './types';
import { shareContent, printContent, copyToClipboard } from '../../utils/sharing';

interface SolutionDisplayProps {
  solution: Solution;
  subject: string;
  onDelete: () => void;
}

export default function SolutionDisplay({ solution, subject, onDelete }: SolutionDisplayProps) {
  const [isCopied, setIsCopied] = useState(false);

  const formatContent = () => {
    return `Solution for ${subject}\n\nStep-by-Step Solution:\n` +
      solution.steps.map((step, i) => `${i + 1}. ${step}`).join('\n') +
      `\n\nExplanation:\n${solution.explanation}\n\nAdditional Resources:\n` +
      solution.resources.map(resource => `- ${resource}`).join('\n');
  };

  const handleShare = async () => {
    try {
      const content = formatContent();
      const success = await shareContent(content, 'Homework Solution');
      if (success) {
        toast.success('Solution shared successfully');
      } else {
        const copied = await copyToClipboard(content);
        if (copied) {
          toast.success('Solution copied to clipboard');
        } else {
          toast.error('Unable to share solution');
        }
      }
    } catch (error) {
      toast.error('Failed to share solution');
    }
  };

  const handlePrint = () => {
    try {
      printContent(formatContent());
      toast.success('Preparing to print solution...');
    } catch (error) {
      toast.error('Failed to print solution');
    }
  };

  const handleCopy = async () => {
    try {
      const success = await copyToClipboard(formatContent());
      if (success) {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        toast.success('Solution copied to clipboard');
      } else {
        toast.error('Failed to copy solution');
      }
    } catch (error) {
      toast.error('Failed to copy solution');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-8"
    >
      <div className="p-6 bg-gray-800/50 rounded-lg border border-gray-700/50">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-gray-200">Solution</h3>
          <div className="flex items-center gap-2">
            <button
              onClick={handleShare}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Share solution"
            >
              <Share2 className="w-4 h-4" />
            </button>
            <button
              onClick={handlePrint}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Print solution"
            >
              <Printer className="w-4 h-4" />
            </button>
            <button
              onClick={handleCopy}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Copy solution"
            >
              {isCopied ? (
                <Check className="w-4 h-4 text-green-400" />
              ) : (
                <Copy className="w-4 h-4" />
              )}
            </button>
            <button
              onClick={onDelete}
              className="p-2 text-gray-400 hover:text-red-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Delete solution"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h4 className="text-sm font-medium text-gray-400 mb-3">
              Step-by-Step Solution
            </h4>
            <div className="space-y-3">
              {solution.steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-start gap-3 p-3 bg-gray-700/30 rounded-lg"
                >
                  <div className="w-6 h-6 flex items-center justify-center bg-indigo-500/20 rounded-full flex-shrink-0">
                    <span className="text-sm font-medium text-indigo-400">
                      {index + 1}
                    </span>
                  </div>
                  <p className="text-gray-300">{step}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-400 mb-3">
              Detailed Explanation
            </h4>
            <div className="p-4 bg-gray-700/30 rounded-lg">
              <p className="text-gray-300 whitespace-pre-wrap">{solution.explanation}</p>
            </div>
          </div>

          {solution.resources.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-400 mb-3">
                Additional Resources
            </h4>
              <div className="space-y-2">
                {solution.resources.map((resource, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 text-indigo-400 hover:text-indigo-300"
                  >
                    <Link className="w-4 h-4" />
                    <a
                      href={resource}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm"
                    >
                      {resource}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}