import { useState, useRef, useEffect } from 'react';

export function useAudioVisualization(isListening: boolean) {
  const [audioData, setAudioData] = useState<number[]>(Array(30).fill(0));
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const microphoneRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    let cleanup = () => {};

    const initializeAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
        
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;
        
        const microphone = audioContext.createMediaStreamSource(stream);
        microphoneRef.current = microphone;
        
        microphone.connect(analyser);
        analyser.fftSize = 64;
        
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const updateAudioData = () => {
          if (isListening) {
            analyser.getByteFrequencyData(dataArray);
            const normalizedData = Array.from(dataArray)
              .slice(0, 30)
              .map(value => value / 255);
            setAudioData(normalizedData);
            animationFrameRef.current = requestAnimationFrame(updateAudioData);
          }
        };

        if (isListening) {
          updateAudioData();
        }

        cleanup = () => {
          if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
          }
          if (microphoneRef.current) {
            microphoneRef.current.disconnect();
          }
          if (analyserRef.current) {
            analyserRef.current.disconnect();
          }
          if (audioContextRef.current) {
            audioContextRef.current.close();
          }
          stream.getTracks().forEach(track => track.stop());
        };
      } catch (err) {
        console.error('Audio initialization error:', err);
      }
    };

    if (isListening) {
      initializeAudio();
    } else {
      setAudioData(Array(30).fill(0));
    }

    return () => cleanup();
  }, [isListening]);

  return audioData;
}