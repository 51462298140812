import React from 'react';
import { Note } from './types';
import NoteCard from './NoteCard';
import TagFilter from './TagFilter';

interface NoteListProps {
  notes: Note[];
  filterTag: string;
  presetTags: readonly string[];
  onFilterChange: (value: string) => void;
  onDeleteNote: (id: string) => void;
  onSetReminder: (note: Note) => void;
}

export default function NoteList({
  notes,
  filterTag,
  presetTags,
  onFilterChange,
  onDeleteNote,
  onSetReminder
}: NoteListProps) {
  return (
    <>
      <div className="mb-4">
        <TagFilter
          value={filterTag}
          presetTags={presetTags}
          onChange={onFilterChange}
        />
      </div>

      <div className="space-y-3 max-h-[400px] overflow-y-auto">
        {notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            onDelete={onDeleteNote}
            onSetReminder={onSetReminder}
          />
        ))}
        {notes.length === 0 && (
          <div className="text-center py-8 text-gray-400">
            No notes found. Start taking notes during your tutoring session!
          </div>
        )}
      </div>
    </>
  );
}