import { useState, useRef, useEffect } from 'react';

interface UseSpeechRecognitionProps {
  onResult?: (text: string) => void;
  onError?: (error: string) => void;
  continuous?: boolean;
  interimResults?: boolean;
  onInterimResult?: (text: string) => void;
}

export function useSpeechRecognition({
  onResult,
  onError,
  onInterimResult,
  continuous = false,
  interimResults = true,
}: UseSpeechRecognitionProps) {
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef<any>(null);
  const silenceTimeoutRef = useRef<NodeJS.Timeout>();
  const finalTranscriptRef = useRef('');

  const startListening = () => {
    if (!('webkitSpeechRecognition' in window)) {
      onError?.('Speech recognition is not supported in your browser.');
      return;
    }

    try {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }

      const recognition = new (window as any).webkitSpeechRecognition();
      recognitionRef.current = recognition;
      
      recognition.continuous = continuous;
      recognition.interimResults = interimResults;
      recognition.lang = 'en-US';

      // Mobile-specific optimizations
      recognition.maxAlternatives = 1;

      recognition.onstart = () => {
        setIsListening(true);
        finalTranscriptRef.current = '';
      };

      recognition.onresult = (event: any) => {
        const results = Array.from(event.results);
        let interimTranscript = '';
        let finalTranscript = finalTranscriptRef.current;

        for (let i = event.resultIndex; i < results.length; i++) {
          const transcript = results[i][0].transcript;
          if (results[i].isFinal) {
            finalTranscript += ' ' + transcript;
          } else {
            interimTranscript += transcript;
          }
        }

        finalTranscriptRef.current = finalTranscript.trim();

        // Handle interim results
        if (interimTranscript) {
          onInterimResult?.(finalTranscript + ' ' + interimTranscript);
        }

        // Reset silence timeout on new speech
        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
        }

        // Set new silence timeout
        silenceTimeoutRef.current = setTimeout(() => {
          if (finalTranscriptRef.current) {
            onResult?.(finalTranscriptRef.current);
            finalTranscriptRef.current = '';
            stopListening();
          }
        }, 1500); // Wait 1.5 seconds of silence before considering speech complete
      };

      recognition.onerror = (event: any) => {
        if (event.error === 'no-speech') {
          return;
        }

        if (event.error !== 'aborted') {
          onError?.('Error occurred while listening. Please try again.');
        }
        setIsListening(false);
      };

      recognition.onend = () => {
        // Send any remaining final transcript before stopping
        if (finalTranscriptRef.current) {
          onResult?.(finalTranscriptRef.current);
          finalTranscriptRef.current = '';
        }
        setIsListening(false);
      };

      recognition.start();
    } catch (error) {
      console.error('Failed to start speech recognition:', error);
      onError?.('Failed to start speech recognition. Please try again.');
      setIsListening(false);
    }
  };

  const stopListening = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
    
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    setIsListening(false);
  };

  useEffect(() => {
    return () => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  return {
    isListening,
    startListening,
    stopListening,
  };
}