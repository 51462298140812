import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { 
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  updatePassword
} from 'firebase/auth';
import { 
  auth, 
  createUserDocument, 
  isUserAdmin,
  signOut,
  hasActiveSession,
  startSessionMonitoring,
  stopSessionMonitoring
} from '../lib/firebase';
import { toast } from 'sonner';

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  signup: (email: string, password: string, name: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUserProfile: (profile: { displayName?: string | null; photoURL?: string | null }) => Promise<void>;
  updateUserEmail: (email: string) => Promise<void>;
  updateUserPassword: (password: string) => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  const signup = async (email: string, password: string, name: string) => {
    try {
      // Check for active session
      const hasSession = await hasActiveSession(email);
      if (hasSession) {
        throw new Error('This account is already logged in on another device or browser');
      }

      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(user, { displayName: name });
      await createUserDocument(user.uid, {
        email,
        displayName: name,
        createdAt: new Date()
      });

      // Start session monitoring
      await startSessionMonitoring(email);
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already registered');
      }
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      // Check for active session
      const hasSession = await hasActiveSession(email);
      if (hasSession) {
        throw new Error('This account is already logged in on another device or browser');
      }

      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await createUserDocument(user.uid, {
        lastLogin: new Date()
      });

      // Start session monitoring
      await startSessionMonitoring(email);
    } catch (error: any) {
      if (error.code === 'auth/invalid-credential') {
        throw new Error('Invalid email or password');
      }
      throw error;
    }
  };

  const logout = async () => {
    try {
      if (currentUser?.email) {
        await stopSessionMonitoring(currentUser.email);
      }
      await signOut();
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };

  const updateUserProfile = async (profile: { displayName?: string | null; photoURL?: string | null }) => {
    if (!currentUser) throw new Error('No user logged in');
    await updateProfile(currentUser, profile);
    await createUserDocument(currentUser.uid, profile);
  };

  const updateUserEmail = async (email: string) => {
    if (!currentUser) throw new Error('No user logged in');
    await updateEmail(currentUser, email);
    await createUserDocument(currentUser.uid, { email });
  };

  const updateUserPassword = async (password: string) => {
    if (!currentUser) throw new Error('No user logged in');
    return updatePassword(currentUser, password);
  };

  const checkAdminStatus = useCallback(async (user: User) => {
    try {
      const adminStatus = await isUserAdmin(user.uid);
      setIsAdmin(adminStatus);
    } catch (error) {
      console.error('Admin check failed:', error);
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await checkAdminStatus(user);
        await startSessionMonitoring(user.email!);
      } else {
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
      if (currentUser?.email) {
        stopSessionMonitoring(currentUser.email).catch(console.error);
      }
    };
  }, [checkAdminStatus]);

  const value = {
    currentUser,
    isAdmin,
    signup,
    login,
    logout,
    resetPassword,
    updateUserProfile,
    updateUserEmail,
    updateUserPassword,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}