import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Bot, User, Share2, Printer, Copy, Check, Square } from 'lucide-react';
import { toast } from 'sonner';
import { shareContent, printContent, copyToClipboard } from '../utils/sharing';
import { useSpeechSynthesis } from '../hooks/useSpeechSynthesis';

interface ChatMessageProps {
  message: string;
  isAI: boolean;
  isSelected?: boolean;
  onToggleSelect?: () => void;
}

export default function ChatMessage({ 
  message, 
  isAI, 
  isSelected,
  onToggleSelect 
}: ChatMessageProps) {
  const [isCopied, setIsCopied] = useState(false);
  const { isSpeaking, stop } = useSpeechSynthesis();

  const handleShare = async () => {
    try {
      const success = await shareContent(message, 'AI Tutor Response');
      if (success) {
        toast.success('Response shared successfully');
      } else {
        const copied = await copyToClipboard(message);
        if (copied) {
          toast.success('Response copied to clipboard');
        } else {
          toast.error('Unable to share response');
        }
      }
    } catch (error) {
      toast.error('Failed to share response');
    }
  };

  const handlePrint = () => {
    try {
      printContent(message);
      toast.success('Preparing to print response...');
    } catch (error) {
      toast.error('Failed to print response');
    }
  };

  const handleCopy = async () => {
    try {
      const success = await copyToClipboard(message);
      if (success) {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        toast.success('Response copied to clipboard');
      } else {
        toast.error('Failed to copy response');
      }
    } catch (error) {
      toast.error('Failed to copy response');
    }
  };

  const handleStopSpeech = () => {
    stop();
    toast.success('Speech stopped');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`flex items-start space-x-3 group ${isAI ? 'flex-row' : 'flex-row-reverse'} ${
        isSelected ? 'bg-indigo-500/10 rounded-lg p-2' : ''
      }`}
    >
      <div className={`p-2 rounded-lg flex-shrink-0 ${
        isAI ? 'bg-indigo-500/20' : 'bg-gray-700/30'
      }`}>
        {isAI ? (
          <Bot className="w-5 h-5 text-indigo-400" />
        ) : (
          <User className="w-5 h-5 text-gray-400" />
        )}
      </div>

      <div className="flex-1">
        <div className={`px-4 py-3 rounded-2xl ${
          isAI ? 'bg-gray-700/30' : 'bg-indigo-500/20'
        }`}>
          <p className="text-gray-200 whitespace-pre-wrap">{message}</p>
        </div>
        
        {isAI && (
          <div className="flex justify-end gap-2 mt-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {isSpeaking && (
              <button
                onClick={handleStopSpeech}
                className="p-1 text-red-400 hover:text-red-300 transition-colors rounded-lg hover:bg-gray-700/30"
                title="Stop speech"
              >
                <Square className="w-4 h-4" />
              </button>
            )}
            <button
              onClick={handleShare}
              className="p-1 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Share response"
            >
              <Share2 className="w-4 h-4" />
            </button>
            <button
              onClick={handlePrint}
              className="p-1 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Print response"
            >
              <Printer className="w-4 h-4" />
            </button>
            <button
              onClick={handleCopy}
              className="p-1 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Copy response"
            >
              {isCopied ? (
                <Check className="w-4 h-4 text-green-400" />
              ) : (
                <Copy className="w-4 h-4" />
              )}
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
}