import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ProfileForm from '../components/profile/ProfileForm';
import NotificationSettings from '../components/profile/NotificationSettings';
import SecuritySettings from '../components/profile/SecuritySettings';

export default function Profile() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
  }, [currentUser, navigate]);

  if (!currentUser) return null;

  return (
    <div className="flex-1 py-8 px-4 bg-gray-900/20">
      <div className="max-w-7xl mx-auto space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-3xl font-bold text-gray-100 mb-2">Account Settings</h1>
          <p className="text-gray-400">Manage your profile, notifications, and security preferences</p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          {/* Main Profile Section */}
          <div className="lg:col-span-8 space-y-6">
            <ProfileForm />
            <SecuritySettings />
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-4 space-y-6">
            <NotificationSettings />
            
            {/* Account Status */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50"
            >
              <h3 className="text-lg font-semibold text-gray-100 mb-4">Account Status</h3>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-400">Email Status</p>
                  <p className="text-sm font-medium text-green-400">Verified</p>
                </div>
                <div>
                  <p className="text-sm text-gray-400">Member Since</p>
                  <p className="text-sm font-medium text-gray-200">
                    {new Date().toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-400">Account Type</p>
                  <p className="text-sm font-medium text-indigo-400">Standard</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}