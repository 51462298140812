import React from 'react';
import AdminStats from '../components/admin/AdminStats';
import UsersList from '../components/admin/UsersList';
import ActivityLog from '../components/admin/ActivityLog';
import UsageChart from '../components/admin/UsageChart';

export default function AdminDashboard() {
  return (
    <main className="flex-1 py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col gap-6">
          <AdminStats />
          <UsersList />
          <ActivityLog />
          <UsageChart />
        </div>
      </div>
    </main>
  );
}