import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Users, Share2, MessageSquare, Save, Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { DataAnalysis } from './types';
import { shareContent } from '../../utils/sharing';

interface CollaborativeProjectProps {
  data: any[];
  columns: string[];
  analysis: DataAnalysis | null;
  onSave?: () => Promise<void>;
  isSaved?: boolean;
}

export default function CollaborativeProject({
  data,
  columns,
  analysis,
  onSave,
  isSaved
}: CollaborativeProjectProps) {
  const [isSharing, setIsSharing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleShare = async () => {
    if (!analysis) return;
    
    setIsSharing(true);
    try {
      const content = JSON.stringify({
        data,
        columns,
        analysis
      }, null, 2);

      const success = await shareContent(content, 'Data Analysis Project');
      if (success) {
        toast.success('Project shared successfully');
      } else {
        toast.error('Unable to share project');
      }
    } catch (error) {
      toast.error('Failed to share project');
    } finally {
      setIsSharing(false);
    }
  };

  const handleSave = async () => {
    if (!onSave) return;
    
    setIsSaving(true);
    try {
      await onSave();
      toast.success('Project saved successfully');
    } catch (error) {
      toast.error('Failed to save project');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="mt-6 p-4 bg-gray-800/50 rounded-lg border border-gray-700/50">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <Users className="w-5 h-5 text-indigo-400" />
          <h3 className="text-lg font-medium text-gray-200">
            Collaborative Project
          </h3>
        </div>
        
        <div className="flex items-center gap-2">
          {onSave && (
            <button
              onClick={handleSave}
              disabled={isSaving || !analysis}
              className={`p-2 rounded-lg transition-colors ${
                isSaved 
                  ? 'text-green-400 hover:text-green-300' 
                  : 'text-gray-400 hover:text-indigo-400'
              } hover:bg-gray-700/30 disabled:opacity-50 disabled:cursor-not-allowed`}
              title={isSaved ? 'Project saved' : 'Save project'}
            >
              {isSaving ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
            </button>
          )}
          
          <button
            onClick={handleShare}
            disabled={isSharing || !analysis}
            className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30 disabled:opacity-50 disabled:cursor-not-allowed"
            title="Share project"
          >
            {isSharing ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <Share2 className="w-4 h-4" />
            )}
          </button>
        </div>
      </div>

      {analysis ? (
        <div className="space-y-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {Object.entries(analysis.summary).map(([key, value]) => (
              <div key={key} className="p-3 bg-gray-700/30 rounded-lg">
                <p className="text-sm text-gray-400 mb-1">{key}</p>
                <p className="text-lg font-semibold text-gray-200">{value}</p>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Object.entries(analysis.columns).map(([column, info]) => (
              <div key={column} className="p-4 bg-gray-700/30 rounded-lg">
                <h4 className="text-sm font-medium text-gray-300 mb-2">
                  {column}
                </h4>
                <p className="text-xs text-gray-400 mb-2">
                  Type: {info.type}
                </p>
                {info.type === 'numeric' && info.stats && (
                  <div className="space-y-1">
                    <p className="text-sm text-gray-300">
                      Min: {info.stats.min}
                    </p>
                    <p className="text-sm text-gray-300">
                      Max: {info.stats.max}
                    </p>
                    <p className="text-sm text-gray-300">
                      Mean: {info.stats.mean.toFixed(2)}
                    </p>
                    <p className="text-sm text-gray-300">
                      Median: {info.stats.median}
                    </p>
                  </div>
                )}
                {info.type === 'categorical' && info.categories && (
                  <div className="space-y-1">
                    {Object.entries(info.categories).map(([category, count]) => (
                      <p key={category} className="text-sm text-gray-300">
                        {category}: {count}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center py-8 text-gray-400">
          Upload and analyze data to start collaborating
        </div>
      )}
    </div>
  );
}