import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Bell, MessageSquare, Phone, Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../lib/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export default function NotificationSettings() {
  const { currentUser } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [notificationPreference, setNotificationPreference] = useState<'sms' | 'voice'>('sms');
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const loadNotificationSettings = async () => {
      if (!currentUser?.uid) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPhoneNumber(userData.phoneNumber || '');
          setNotificationPreference(userData.notificationPreference || 'sms');
        }
      } catch (error) {
        console.error('Error loading notification settings:', error);
        toast.error('Failed to load notification settings');
      } finally {
        setInitialLoad(false);
      }
    };

    loadNotificationSettings();
  }, [currentUser]);

  const validatePhoneNumber = (number: string) => {
    // Basic phone number validation
    const phoneRegex = /^\+[1-9]\d{10,14}$/;
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!phoneNumber.trim()) {
      toast.error('Please enter a phone number');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      toast.error('Please enter a valid phone number with country code (e.g., +12345678900)');
      return;
    }

    setLoading(true);
    try {
      const userRef = doc(db, 'users', currentUser!.uid);
      await setDoc(userRef, {
        phoneNumber,
        notificationPreference,
        notificationsEnabled: true,
        updatedAt: new Date()
      }, { merge: true });

      toast.success('Notification settings saved successfully');
    } catch (error) {
      console.error('Error saving notification settings:', error);
      toast.error('Failed to save notification settings');
    } finally {
      setLoading(false);
    }
  };

  if (initialLoad) {
    return (
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50">
        <div className="flex items-center justify-center">
          <Loader2 className="w-6 h-6 text-indigo-400 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50"
    >
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-indigo-500/20 rounded-lg">
          <Bell className="w-5 h-5 text-indigo-400" />
        </div>
        <h2 className="text-xl font-semibold text-gray-200">Notification Settings</h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Phone Number
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+12345678900"
            className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
          <p className="mt-1 text-sm text-gray-400">
            Enter your phone number with country code (e.g., +1 for US)
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Notification Type
          </label>
          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={() => setNotificationPreference('sms')}
              className={`flex items-center justify-center gap-2 p-3 rounded-lg border ${
                notificationPreference === 'sms'
                  ? 'bg-indigo-500/20 border-indigo-500'
                  : 'bg-gray-700/30 border-gray-600/50'
              }`}
            >
              <MessageSquare className="w-5 h-5" />
              <span>SMS</span>
            </button>
            <button
              type="button"
              onClick={() => setNotificationPreference('voice')}
              className={`flex items-center justify-center gap-2 p-3 rounded-lg border ${
                notificationPreference === 'voice'
                  ? 'bg-indigo-500/20 border-indigo-500'
                  : 'bg-gray-700/30 border-gray-600/50'
              }`}
            >
              <Phone className="w-5 h-5" />
              <span>Voice Call</span>
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 bg-indigo-500 text-white rounded-lg font-medium hover:bg-indigo-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <span className="flex items-center justify-center gap-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              Saving...
            </span>
          ) : (
            'Save Settings'
          )}
        </button>
      </form>
    </motion.div>
  );
}