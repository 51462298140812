import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  BookOpenCheck, 
  Brain, 
  MessageSquare, 
  Mic, 
  StickyNote, 
  Share2, 
  ArrowRight,
  CheckCircle 
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export default function Home() {
  const { currentUser } = useAuth();

  const features = [
    {
      icon: <Brain className="w-5 h-5 sm:w-6 sm:h-6" />,
      title: "AI-Powered Learning",
      description: "Get instant help with any subject through our advanced AI tutor."
    },
    {
      icon: <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6" />,
      title: "Natural Conversations",
      description: "Engage in natural dialogue with voice or text interactions."
    },
    {
      icon: <StickyNote className="w-5 h-5 sm:w-6 sm:h-6" />,
      title: "Smart Note-Taking",
      description: "Organize your study notes with tags and smart categorization."
    },
    {
      icon: <Share2 className="w-5 h-5 sm:w-6 sm:h-6" />,
      title: "Easy Sharing",
      description: "Share your notes and tutoring sessions with classmates."
    }
  ];

  const benefits = [
    "24/7 Learning Support",
    "Personalized Tutoring",
    "Voice & Text Interaction",
    "Organized Study Notes",
    "Progress Tracking",
    "Easy Content Sharing"
  ];

  return (
    <div className="flex-1">
      {/* Hero Section */}
      <section className="relative overflow-hidden py-12 sm:py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <div className="flex justify-center mb-6 sm:mb-8">
              <div className="p-3 bg-accent/20 rounded-2xl">
                <BookOpenCheck className="w-10 h-10 sm:w-12 sm:h-12 text-accent-light" />
              </div>
            </div>
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-4 sm:mb-6">
              Your Personal AI Study Companion
            </h1>
            <p className="text-lg sm:text-xl text-gray-300 mb-6 sm:mb-8">
              Get AI-powered help with any subject, take smart notes, and boost your learning!
            </p>
            <div className="flex flex-col sm:flex-row gap-3 justify-center">
              <Link
                to={currentUser ? "/dashboard" : "/signup"}
                className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-indigo-500 text-white font-medium hover:bg-indigo-600 transition-colors"
              >
                {currentUser ? "Go to Dashboard" : "Get Started Free"}
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
              {!currentUser && (
                <Link
                  to="/login"
                  className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-gray-700/50 text-white font-medium hover:bg-gray-700/70 transition-colors"
                >
                  Sign In
                </Link>
              )}
            </div>
          </motion.div>
        </div>

        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
          <div className="absolute top-1/4 left-0 w-64 h-64 bg-indigo-500/10 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-1/4 right-0 w-64 h-64 bg-purple-500/10 rounded-full filter blur-3xl"></div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12 sm:py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6"
          >
            {features.map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50"
              >
                <div className="p-2 bg-indigo-500/20 rounded-lg w-fit mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-lg sm:text-xl font-semibold text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-sm sm:text-base text-gray-400">
                  {feature.description}
                </p>
              </div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-12 sm:py-16 px-4 bg-gray-800/30">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-8 sm:mb-12"
          >
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4">
              Why Choose Tutor Buddy?
            </h2>
            <p className="text-lg sm:text-xl text-gray-300">
              Everything you need to enhance your learning experience
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
          >
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className="flex items-center gap-3 p-4 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-700/50"
              >
                <CheckCircle className="w-5 h-5 text-green-400 flex-shrink-0" />
                <span className="text-sm sm:text-base text-gray-200">{benefit}</span>
              </div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 sm:py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6">
              Ready to Transform Your Learning Experience?
            </h2>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to={currentUser ? "/dashboard" : "/signup"}
                className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-indigo-500 text-white font-medium hover:bg-indigo-600 transition-colors"
              >
                {currentUser ? "Go to Dashboard" : "Start Learning Now"}
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}