export interface Note {
  id: string;
  content: string;
  timestamp: number;
  tags: string[];
  reminder: string | null;
  reminderShown: boolean;
}

export const SUBJECT_COLORS = {
  'Mathematics': { 
    bg: 'bg-blue-100/10', 
    text: 'text-blue-300', 
    hover: 'hover:bg-blue-100/20' 
  },
  'Physics': { 
    bg: 'bg-purple-100/10', 
    text: 'text-purple-300', 
    hover: 'hover:bg-purple-100/20' 
  },
  'Chemistry': { 
    bg: 'bg-emerald-100/10', 
    text: 'text-emerald-300', 
    hover: 'hover:bg-emerald-100/20' 
  },
  'Biology': { 
    bg: 'bg-rose-100/10', 
    text: 'text-rose-300', 
    hover: 'hover:bg-rose-100/20' 
  },
  'History': { 
    bg: 'bg-amber-100/10', 
    text: 'text-amber-300', 
    hover: 'hover:bg-amber-100/20' 
  },
  'Literature': { 
    bg: 'bg-pink-100/10', 
    text: 'text-pink-300', 
    hover: 'hover:bg-pink-100/20' 
  },
  'Computer Science': { 
    bg: 'bg-cyan-100/10', 
    text: 'text-cyan-300', 
    hover: 'hover:bg-cyan-100/20' 
  }
} as const;

export const PRESET_TAGS = Object.keys(SUBJECT_COLORS);