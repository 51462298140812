import React from 'react';
import { Calendar, Clock, Target, Loader2 } from 'lucide-react';
import { StudyPreferences } from './types';

interface StudyPlanFormProps {
  preferences: StudyPreferences;
  onPreferencesChange: (preferences: StudyPreferences) => void;
  onSubmit: (e: React.FormEvent) => void;
  isGenerating: boolean;
}

export default function StudyPlanForm({ 
  preferences, 
  onPreferencesChange, 
  onSubmit,
  isGenerating 
}: StudyPlanFormProps) {
  const handleChange = (field: keyof StudyPreferences, value: string | number) => {
    onPreferencesChange({
      ...preferences,
      [field]: value
    });
  };

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Subject
        </label>
        <input
          type="text"
          value={preferences.subject}
          onChange={(e) => handleChange('subject', e.target.value)}
          className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400"
          placeholder="Enter subject or topic"
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Daily Study Hours
          </label>
          <div className="flex items-center gap-2">
            <input
              type="number"
              min="1"
              max="12"
              value={preferences.dailyHours}
              onChange={(e) => handleChange('dailyHours', parseInt(e.target.value) || 1)}
              className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200"
            />
            <Clock className="w-5 h-5 text-gray-400" />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Days per Week
          </label>
          <div className="flex items-center gap-2">
            <input
              type="number"
              min="1"
              max="7"
              value={preferences.daysPerWeek}
              onChange={(e) => handleChange('daysPerWeek', parseInt(e.target.value) || 1)}
              className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200"
            />
            <Calendar className="w-5 h-5 text-gray-400" />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Difficulty Level
        </label>
        <select
          value={preferences.difficulty}
          onChange={(e) => handleChange('difficulty', e.target.value as StudyPreferences['difficulty'])}
          className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200"
        >
          <option value="beginner">Beginner</option>
          <option value="intermediate">Intermediate</option>
          <option value="advanced">Advanced</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Learning Goals
        </label>
        <textarea
          value={preferences.goals}
          onChange={(e) => handleChange('goals', e.target.value)}
          className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400 resize-none h-24"
          placeholder="What do you want to achieve?"
        />
      </div>

      <button
        type="submit"
        disabled={isGenerating}
        className="w-full px-4 py-2 bg-indigo-500 text-white rounded-lg font-medium hover:bg-indigo-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
      >
        {isGenerating ? (
          <>
            <Loader2 className="w-4 h-4 animate-spin" />
            Generating Plan...
          </>
        ) : (
          <>
            <Target className="w-4 h-4" />
            Generate Study Plan
          </>
        )}
      </button>
    </form>
  );
}