import React from 'react';
import { motion } from 'framer-motion';
import { Users, Zap, Clock, Activity } from 'lucide-react';

interface StatsProps {
  stats: {
    totalUsers: number;
    activeUsers: number;
    totalInteractions: number;
    averageResponseTime: number;
  };
  isLoading: boolean;
}

export default function AdminStats({ stats, isLoading }: StatsProps) {
  const statItems = [
    {
      label: 'Total Users',
      value: stats.totalUsers,
      icon: Users,
      color: 'text-blue-500',
      bgColor: 'bg-blue-500/20'
    },
    {
      label: 'Active Users',
      value: stats.activeUsers,
      icon: Activity,
      color: 'text-green-500',
      bgColor: 'bg-green-500/20'
    },
    {
      label: 'Total Interactions',
      value: stats.totalInteractions,
      icon: Zap,
      color: 'text-yellow-500',
      bgColor: 'bg-yellow-500/20'
    },
    {
      label: 'Avg Response Time',
      value: `${stats.averageResponseTime}s`,
      icon: Clock,
      color: 'text-purple-500',
      bgColor: 'bg-purple-500/20'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {statItems.map((item, index) => {
        const Icon = item.icon;
        
        return (
          <motion.div
            key={item.label}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50"
          >
            <div className="flex items-center justify-between">
              <div className={`p-2 ${item.bgColor} rounded-lg`}>
                <Icon className={`w-5 h-5 ${item.color}`} />
              </div>
              {isLoading ? (
                <div className="h-2 w-12 bg-gray-700 rounded animate-pulse"></div>
              ) : (
                <span className="text-sm text-gray-400">Last 24h</span>
              )}
            </div>
            
            <div className="mt-4">
              {isLoading ? (
                <>
                  <div className="h-6 w-20 bg-gray-700 rounded animate-pulse mb-2"></div>
                  <div className="h-4 w-16 bg-gray-700 rounded animate-pulse"></div>
                </>
              ) : (
                <>
                  <h4 className="text-2xl font-semibold text-white">
                    {typeof item.value === 'number' 
                      ? item.value.toLocaleString()
                      : item.value}
                  </h4>
                  <p className="text-gray-400">{item.label}</p>
                </>
              )}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}