import React from 'react';
import { Filter } from 'lucide-react';
import { SUBJECT_COLORS } from './types';

interface TagFilterProps {
  value: string;
  presetTags: readonly string[];
  onChange: (value: string) => void;
}

export default function TagFilter({ value, presetTags, onChange }: TagFilterProps) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
      <button
        onClick={() => onChange('')}
        className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-colors ${
          value === ''
            ? 'bg-indigo-100 text-indigo-700'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        }`}
      >
        All Notes
      </button>
      {presetTags.map(tag => {
        const colors = SUBJECT_COLORS[tag as keyof typeof SUBJECT_COLORS];
        return (
          <button
            key={tag}
            onClick={() => onChange(tag)}
            className={`px-3 py-2 rounded-lg text-xs sm:text-sm font-medium transition-colors ${
              value === tag
                ? `${colors.bg} ${colors.text}`
                : `${colors.bg} ${colors.text} opacity-60 hover:opacity-100`
            }`}
          >
            {tag}
          </button>
        );
      })}
    </div>
  );
}