import { useState, useCallback, useRef, useEffect } from 'react';

interface SpeechOptions {
  rate?: number;
  pitch?: number;
  volume?: number;
  voice?: SpeechSynthesisVoice;
}

export function useSpeechSynthesis() {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);
  const voicesLoadedRef = useRef(false);
  const selectedVoiceRef = useRef<SpeechSynthesisVoice | null>(null);
  const textChunksRef = useRef<string[]>([]);
  const currentChunkRef = useRef(0);
  const isStoppingRef = useRef(false);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      const femaleVoice = voices.find(voice => 
        voice.lang.includes('en') && 
        voice.name.toLowerCase().includes('female')
      ) || voices.find(voice =>
        voice.lang.includes('en') &&
        (voice.name.toLowerCase().includes('samantha') || 
         voice.name.toLowerCase().includes('karen') ||    
         voice.name.toLowerCase().includes('microsoft zira'))  
      ) || voices.find(voice => voice.lang.includes('en'));

      if (femaleVoice) {
        selectedVoiceRef.current = femaleVoice;
      }
      voicesLoadedRef.current = true;
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
    
    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  // Split text into mobile-optimized chunks
  const splitTextIntoChunks = (text: string): string[] => {
    // Split on sentence boundaries for more natural pauses
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text];
    const chunks: string[] = [];
    let currentChunk = '';

    for (const sentence of sentences) {
      // Use smaller chunks for mobile devices
      if (currentChunk.length + sentence.length < 100) {
        currentChunk += sentence;
      } else {
        if (currentChunk) chunks.push(currentChunk.trim());
        currentChunk = sentence;
      }
    }
    if (currentChunk) chunks.push(currentChunk.trim());
    return chunks;
  };

  const speakNextChunk = useCallback(() => {
    if (isStoppingRef.current || currentChunkRef.current >= textChunksRef.current.length) {
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
      return;
    }

    const chunk = textChunksRef.current[currentChunkRef.current];
    const utterance = new SpeechSynthesisUtterance(chunk);
    utteranceRef.current = utterance;

    // Mobile-optimized speech settings
    utterance.rate = 0.9; // Slightly slower for better clarity
    utterance.pitch = 1.1;
    utterance.volume = 1;

    if (selectedVoiceRef.current) {
      utterance.voice = selectedVoiceRef.current;
    }

    // Handle mobile-specific events
    utterance.onstart = () => {
      setIsSpeaking(true);
    };

    utterance.onend = () => {
      // Complete current sentence before stopping
      if (isStoppingRef.current) {
        setIsSpeaking(false);
        currentChunkRef.current = 0;
        textChunksRef.current = [];
        isStoppingRef.current = false;
        return;
      }

      currentChunkRef.current++;
      // Small delay between chunks for mobile
      setTimeout(() => {
        speakNextChunk();
      }, 250);
    };

    utterance.onerror = (event) => {
      console.error('Speech synthesis error:', event);
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
    };

    // Prevent audio context suspension on mobile
    const resumeAudioContext = () => {
      if (window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      }
    };

    document.addEventListener('visibilitychange', resumeAudioContext);
    window.addEventListener('focus', resumeAudioContext);

    window.speechSynthesis.speak(utterance);

    return () => {
      document.removeEventListener('visibilitychange', resumeAudioContext);
      window.removeEventListener('focus', resumeAudioContext);
    };
  }, []);

  const speak = useCallback((text: string, options: SpeechOptions = {}) => {
    if (!text || isSpeaking) return;

    // Cancel any ongoing speech
    stop();

    // Split text into mobile-optimized chunks
    textChunksRef.current = splitTextIntoChunks(text);
    currentChunkRef.current = 0;
    isStoppingRef.current = false;
    setIsSpeaking(true);

    const startSpeaking = () => {
      // Ensure audio context is resumed
      if (window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      }
      speakNextChunk();
    };

    if (voicesLoadedRef.current) {
      startSpeaking();
    } else {
      const maxAttempts = 10;
      let attempts = 0;
      
      const trySpeak = () => {
        if (window.speechSynthesis.getVoices().length > 0) {
          startSpeaking();
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(trySpeak, 100);
        }
      };
      
      trySpeak();
    }
  }, [isSpeaking, speakNextChunk]);

  const stop = useCallback(() => {
    // Set flag to stop after current sentence
    isStoppingRef.current = true;
  }, []);

  const pause = useCallback(() => {
    window.speechSynthesis.pause();
  }, []);

  const resume = useCallback(() => {
    window.speechSynthesis.resume();
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (utteranceRef.current) {
        window.speechSynthesis.cancel();
      }
      setIsSpeaking(false);
      currentChunkRef.current = 0;
      textChunksRef.current = [];
      isStoppingRef.current = false;
    };
  }, []);

  return { speak, stop, pause, resume, isSpeaking };
}