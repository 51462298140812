import { openai } from './openai';
import { HomeworkAssignment, Solution } from '../components/homework/types';

export async function generateSolution(assignment: HomeworkAssignment): Promise<Solution> {
  try {
    const prompt = `Help solve this ${assignment.subject} problem:
${assignment.question}

Please provide:
1. A step-by-step solution
2. A detailed explanation
3. Relevant educational resources

Format the response as a JSON object with:
- steps: Array of strings for each solution step
- explanation: String with detailed explanation
- resources: Array of relevant educational resource URLs`;

    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: "You are an expert tutor specializing in providing detailed, educational solutions to homework problems. Focus on explaining concepts clearly and thoroughly."
        },
        { role: "user", content: prompt }
      ],
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      response_format: { type: "json_object" },
    });

    const response = JSON.parse(completion.choices[0].message.content);

    return {
      steps: response.steps || [],
      explanation: response.explanation || "No explanation provided.",
      resources: response.resources || []
    };
  } catch (error) {
    console.error('Error generating solution:', error);
    throw new Error('Failed to generate solution');
  }
}