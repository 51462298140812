import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mic, MicOff, Loader2 } from 'lucide-react';
import { useSpeechRecognition } from '../hooks/useSpeechRecognition';
import { useAudioVisualization } from '../hooks/useAudioVisualization';
import AudioWaveform from './audio/AudioWaveform';

interface VoiceWidgetProps {
  onSpeechResult: (text: string) => void;
  isProcessing: boolean;
}

export default function VoiceWidget({ onSpeechResult, isProcessing }: VoiceWidgetProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentTranscript, setCurrentTranscript] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const checkMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const { isListening, startListening, stopListening } = useSpeechRecognition({
    onResult: (text) => {
      if (text.trim()) {
        onSpeechResult(text);
        setCurrentTranscript('');
      }
    },
    onInterimResult: (text) => {
      setCurrentTranscript(text);
    },
    onError: (error) => {
      setErrorMessage(error);
      setTimeout(() => setErrorMessage(''), 3000);
    },
    continuous: true,
    interimResults: true
  });

  const audioData = useAudioVisualization(isListening);

  const toggleListening = () => {
    if (isListening) {
      stopListening();
      setCurrentTranscript('');
    } else {
      setErrorMessage('');
      startListening();
    }
  };

  // Mobile-specific touch handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    if (!isListening && !isProcessing) {
      startListening();
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    if (isListening) {
      stopListening();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={isMobile ? undefined : toggleListening}
          onTouchStart={isMobile ? handleTouchStart : undefined}
          onTouchEnd={isMobile ? handleTouchEnd : undefined}
          disabled={isProcessing}
          className={`relative p-3 sm:p-4 rounded-full transition-colors duration-300 ${
            isProcessing
              ? 'bg-gray-700/30 cursor-not-allowed'
              : isListening
              ? 'bg-red-500 hover:bg-red-600'
              : 'bg-indigo-500 hover:bg-indigo-600'
          } shadow-lg touch-none`}
          title={isListening ? 'Stop listening' : 'Start listening'}
        >
          {isProcessing ? (
            <Loader2 className="w-5 h-5 text-gray-400 animate-spin" />
          ) : isListening ? (
            <MicOff className="w-5 h-5 text-white" />
          ) : (
            <Mic className="w-5 h-5 text-white" />
          )}
          <AudioWaveform audioData={audioData} isListening={isListening} />
        </motion.button>
      </div>

      {(currentTranscript || errorMessage) && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-2 max-w-[200px] text-center text-sm"
        >
          {errorMessage ? (
            <span className="text-red-400">{errorMessage}</span>
          ) : (
            <span className="text-gray-300 bg-gray-700/30 p-2 rounded-lg block">
              {currentTranscript}
            </span>
          )}
        </motion.div>
      )}

      {isMobile && !isListening && !isProcessing && (
        <p className="mt-2 text-xs text-gray-400 text-center">
          Press and hold to speak
        </p>
      )}
    </div>
  );
}