import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Save } from 'lucide-react';
import { toast } from 'sonner';
import TagInput from './TagInput';
import VoiceWidget from '../VoiceWidget';

interface NoteEditorProps {
  content: string;
  selectedTags: string[];
  showTagInput: boolean;
  customTag: string;
  presetTags: readonly string[];
  onContentChange: (content: string) => void;
  onSave: () => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
  onShowTagInput: () => void;
  onCustomTagChange: (value: string) => void;
  onCustomTagSubmit: (e: React.FormEvent) => void;
}

export default function NoteEditor({
  content,
  selectedTags,
  showTagInput,
  customTag,
  presetTags,
  onContentChange,
  onSave,
  onAddTag,
  onRemoveTag,
  onShowTagInput,
  onCustomTagChange,
  onCustomTagSubmit
}: NoteEditorProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const lastCursorPosition = useRef<number>(0);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    lastCursorPosition.current = e.target.selectionStart;
    onContentChange(e.target.value);
  };

  const insertTextAtCursor = (text: string) => {
    const position = lastCursorPosition.current;
    const newContent = content.slice(0, position) + text + content.slice(position);
    onContentChange(newContent);
    lastCursorPosition.current = position + text.length;
    
    if (textareaRef.current) {
      setTimeout(() => {
        textareaRef.current?.setSelectionRange(
          lastCursorPosition.current,
          lastCursorPosition.current
        );
        textareaRef.current?.focus();
      }, 0);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex gap-4 mb-4">
        <div className="flex-1 relative">
          <textarea
            ref={textareaRef}
            value={content}
            onChange={handleTextareaChange}
            onClick={(e) => {
              lastCursorPosition.current = e.currentTarget.selectionStart;
            }}
            onKeyUp={(e) => {
              lastCursorPosition.current = e.currentTarget.selectionStart;
            }}
            placeholder="Take notes during your tutoring session..."
            className="w-full h-32 p-3 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none"
          />
        </div>
        
        <VoiceWidget
          onSpeechResult={(text) => {
            if (text.trim()) {
              insertTextAtCursor(text + ' ');
            }
          }}
          isProcessing={isProcessing}
        />
      </div>

      <TagInput
        selectedTags={selectedTags}
        showTagInput={showTagInput}
        customTag={customTag}
        presetTags={presetTags}
        onAddTag={onAddTag}
        onRemoveTag={onRemoveTag}
        onShowTagInput={onShowTagInput}
        onCustomTagChange={onCustomTagChange}
        onCustomTagSubmit={onCustomTagSubmit}
      />

      <button
        onClick={onSave}
        className="mt-4 flex items-center gap-2 px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors"
      >
        <Save className="w-4 h-4" />
        Save Note
      </button>
    </div>
  );
}