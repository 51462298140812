import React, { useCallback, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Upload, FileText, Loader2, X } from 'lucide-react';
import { toast } from 'sonner';
import { HomeworkAssignment } from './types';

interface HomeworkUploaderProps {
  onSubmit: (assignment: HomeworkAssignment) => Promise<void>;
  isProcessing: boolean;
}

export default function HomeworkUploader({ onSubmit, isProcessing }: HomeworkUploaderProps) {
  const [subject, setSubject] = useState('');
  const [question, setQuestion] = useState('');
  const [file, setFile] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  const handleFile = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size should be less than 5MB');
      return;
    }

    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
      'image/jpeg',
      'image/png'
    ];

    if (!allowedTypes.includes(file.type)) {
      toast.error('Invalid file type. Please upload PDF, Word, Text, or Image files.');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setFile(reader.result as string);
      setFileName(file.name);
    };
    reader.onerror = () => {
      toast.error('Failed to read file');
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.add('border-indigo-500');
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.remove('border-indigo-500');
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.remove('border-indigo-500');
    }

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  }, []);

  const removeFile = () => {
    setFile(undefined);
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!subject.trim() || !question.trim()) {
      toast.error('Please fill in all required fields');
      return;
    }

    const assignment: HomeworkAssignment = {
      id: Date.now().toString(),
      subject: subject.trim(),
      question: question.trim(),
      file,
      timestamp: Date.now()
    };

    await onSubmit(assignment);
    setSubject('');
    setQuestion('');
    setFile(undefined);
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Subject
        </label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400"
          placeholder="Enter subject (e.g., Mathematics, Physics)"
          disabled={isProcessing}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Question or Problem
        </label>
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="w-full px-4 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200 placeholder-gray-400 resize-none h-32"
          placeholder="Describe your question or problem in detail..."
          disabled={isProcessing}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Attachment (Optional)
        </label>
        <div
          ref={dropZoneRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600/50 border-dashed rounded-lg transition-colors"
        >
          <div className="space-y-1 text-center">
            {file ? (
              <div className="flex flex-col items-center">
                <FileText className="mx-auto h-12 w-12 text-indigo-400" />
                <div className="mt-2 flex items-center gap-2">
                  <span className="text-sm text-gray-300">{fileName}</span>
                  <button
                    type="button"
                    onClick={removeFile}
                    className="p-1 hover:bg-gray-700/50 rounded-full transition-colors"
                  >
                    <X className="w-4 h-4 text-gray-400 hover:text-red-400" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-400">
                  <label className="relative cursor-pointer rounded-md font-medium text-indigo-400 hover:text-indigo-300 focus-within:outline-none">
                    <span>Upload a file</span>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="sr-only"
                      accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
                      onChange={handleFileChange}
                      disabled={isProcessing}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-400">
                  PDF, Word, Text, or Image up to 5MB
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={isProcessing}
        className="w-full px-4 py-2 bg-indigo-500 text-white rounded-lg font-medium hover:bg-indigo-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
      >
        {isProcessing ? (
          <>
            <Loader2 className="w-4 h-4 animate-spin" />
            Processing...
          </>
        ) : (
          <>
            <Upload className="w-4 h-4" />
            Submit Question
          </>
        )}
      </button>
    </form>
  );
}