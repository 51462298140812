import { DataAnalysis } from '../components/analysis/types';

export async function analyzeData(data: any[], columns: string[]): Promise<DataAnalysis> {
  const analysis: DataAnalysis = {
    summary: {
      totalRows: data.length,
      totalColumns: columns.length,
      numericColumns: 0,
      categoricalColumns: 0,
      missingValues: 0
    },
    columns: {}
  };

  columns.forEach(column => {
    const values = data.map(row => row[column]);
    const isNumeric = values.every(value => 
      value === null || value === '' || !isNaN(Number(value))
    );

    analysis.columns[column] = {
      type: isNumeric ? 'numeric' : 'categorical'
    };

    if (isNumeric) {
      analysis.summary.numericColumns++;
      const numericValues = values
        .filter(value => value !== null && value !== '')
        .map(Number);

      if (numericValues.length) {
        analysis.columns[column].stats = {
          min: Math.min(...numericValues),
          max: Math.max(...numericValues),
          mean: numericValues.reduce((a, b) => a + b, 0) / numericValues.length,
          median: getMedian(numericValues)
        };
      }
    } else {
      analysis.summary.categoricalColumns++;
      const categories: { [key: string]: number } = {};
      values.forEach(value => {
        if (value === null || value === '') return;
        categories[value] = (categories[value] || 0) + 1;
      });
      analysis.columns[column].categories = categories;
    }

    const missingCount = values.filter(value => 
      value === null || value === ''
    ).length;
    analysis.summary.missingValues += missingCount;
  });

  return analysis;
}

function getMedian(numbers: number[]): number {
  const sorted = numbers.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}