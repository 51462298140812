import React, { useState } from 'react';
import { Calendar } from 'lucide-react';
import { toast } from 'sonner';
import { generateStudyPlan } from '../../lib/planner';
import type { StudyPlan, StudyPreferences } from './types';
import StudyPlanForm from './StudyPlanForm';
import StudyPlanDisplay from './StudyPlanDisplay';
import { useAuth } from '../../contexts/AuthContext';
import { saveStudyPlan, deleteStudyPlan } from '../../lib/firebase';

export default function StudyPlanGenerator() {
  const { currentUser } = useAuth();
  const [preferences, setPreferences] = useState<StudyPreferences>({
    subject: '',
    dailyHours: 2,
    daysPerWeek: 5,
    difficulty: 'intermediate',
    goals: ''
  });

  const [plan, setPlan] = useState<StudyPlan | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [planId, setPlanId] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!preferences.subject || !preferences.goals) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsGenerating(true);
    try {
      const generatedPlan = await generateStudyPlan(preferences);
      setPlan(generatedPlan);
      setIsSaved(false);
      setPlanId(null);
      toast.success('Study plan generated successfully!');
    } catch (error) {
      console.error('Failed to generate study plan:', error);
      toast.error('Failed to generate study plan. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    if (!currentUser || !plan) return;

    try {
      const id = await saveStudyPlan(currentUser.uid, {
        id: planId || Date.now().toString(),
        plan,
        preferences,
        timestamp: Date.now()
      });
      setPlanId(id);
      setIsSaved(true);
      toast.success('Study plan saved successfully!');
    } catch (error) {
      console.error('Failed to save study plan:', error);
      toast.error('Failed to save study plan');
    }
  };

  const handleDelete = async () => {
    if (planId && currentUser) {
      try {
        await deleteStudyPlan(currentUser.uid, planId);
      } catch (error) {
        console.error('Failed to delete study plan:', error);
      }
    }
    setPlan(null);
    setIsSaved(false);
    setPlanId(null);
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-indigo-500/20 rounded-lg">
          <Calendar className="w-5 h-5 text-indigo-400" />
        </div>
        <h2 className="text-xl font-semibold text-gray-200">Study Planner</h2>
      </div>

      <StudyPlanForm
        preferences={preferences}
        onPreferencesChange={setPreferences}
        onSubmit={handleSubmit}
        isGenerating={isGenerating}
      />

      {plan && (
        <StudyPlanDisplay
          plan={plan}
          subject={preferences.subject}
          onDelete={handleDelete}
          onSave={currentUser ? handleSave : undefined}
          isSaved={isSaved}
        />
      )}
    </div>
  );
}