import React from 'react';
import { motion } from 'framer-motion';

interface AudioWaveformProps {
  audioData: number[];
  isListening: boolean;
}

export default function AudioWaveform({ audioData, isListening }: AudioWaveformProps) {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="flex items-end gap-0.5">
        {audioData.map((value, index) => (
          <motion.div
            key={index}
            initial={{ height: 0 }}
            animate={{ 
              height: isListening ? `${value * 100}%` : '2px',
              opacity: isListening ? 0.6 + value * 0.4 : 0.3
            }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            className={`w-0.5 rounded-full ${
              isListening 
                ? 'bg-gradient-to-t from-indigo-500 to-indigo-300'
                : 'bg-gray-400'
            }`}
            style={{ 
              transformOrigin: 'bottom',
              minHeight: '2px'
            }}
          />
        ))}
      </div>
    </div>
  );
}