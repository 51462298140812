import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BookOpenCheck, Menu, X, User, Settings, LogOut } from 'lucide-react';
import { toast } from 'sonner';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

export default function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showProfileMenu, setShowProfileMenu] = React.useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Successfully logged out');
      navigate('/');
      setIsMenuOpen(false);
      setShowProfileMenu(false);
    } catch (error) {
      toast.error('Failed to log out');
    }
  };

  const handleDashboardClick = () => {
    if (location.pathname === '/dashboard') return;
    navigate('/dashboard');
    setIsMenuOpen(false);
    setShowProfileMenu(false);
  };

  return (
    <header className="sticky top-0 z-50 bg-primary/90 backdrop-blur-sm border-b border-accent/20">
      <nav className="max-w-7xl mx-auto px-4 h-20">
        <div className="flex items-center justify-between h-full">
          {/* Logo & Brand */}
          <Link 
            to="/"
            className="flex items-center gap-2 sm:gap-3 group transition-colors"
            onClick={() => setIsMenuOpen(false)}
          >
            <motion.div 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 sm:p-2.5 bg-accent/20 rounded-lg group-hover:bg-accent/30 transition-colors"
            >
              <BookOpenCheck className="w-5 h-5 sm:w-6 sm:h-6 text-accent-light group-hover:scale-110 transition-transform" />
            </motion.div>
            <div>
              <h1 className="text-lg sm:text-xl font-bold text-gradient group-hover:opacity-90">
                Tutor Buddy
              </h1>
              <p className="text-xs sm:text-sm text-gray-300/80 group-hover:text-gray-300/90">
                Your AI Study Companion
              </p>
            </div>
          </Link>

          {/* Mobile Menu Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 text-gray-300 hover:text-white md:hidden"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </motion.button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-4">
            {currentUser ? (
              <div className="flex items-center gap-4">
                {location.pathname !== '/dashboard' && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleDashboardClick}
                    className="px-4 py-2 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors"
                  >
                    Go to Dashboard
                  </motion.button>
                )}
                <div className="relative">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                    className="flex items-center gap-2 p-2 rounded-lg bg-gray-700/30 hover:bg-gray-700/50 transition-colors"
                  >
                    <User className="w-5 h-5 text-gray-300" />
                    <span className="text-sm font-medium text-gray-300">
                      {currentUser.displayName?.split(' ')[0] || 'Profile'}
                    </span>
                  </motion.button>
                  
                  <AnimatePresence>
                    {showProfileMenu && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg border border-gray-700/50 overflow-hidden"
                      >
                        <div className="p-2">
                          <Link
                            to="/profile"
                            onClick={() => setShowProfileMenu(false)}
                            className="flex items-center gap-2 px-3 py-2 text-gray-300 hover:bg-gray-700/50 rounded-lg transition-colors"
                          >
                            <Settings className="w-4 h-4" />
                            Profile Settings
                          </Link>
                          <button
                            onClick={handleLogout}
                            className="w-full flex items-center gap-2 px-3 py-2 text-red-400 hover:bg-gray-700/50 rounded-lg transition-colors"
                          >
                            <LogOut className="w-4 h-4" />
                            Sign Out
                          </button>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-3">
                <Link
                  to="/login"
                  className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white transition-colors"
                >
                  Sign In
                </Link>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link
                    to="/signup"
                    className="px-4 py-2 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors"
                  >
                    Get Started
                  </Link>
                </motion.div>
              </div>
            )}
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isMenuOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute top-full left-0 right-0 bg-primary/95 backdrop-blur-sm border-b border-accent/20 md:hidden"
              >
                <div className="flex flex-col p-4 space-y-3">
                  {currentUser ? (
                    <>
                      <div className="px-4 py-2 border-b border-gray-700/50">
                        <p className="text-sm text-gray-400">Signed in as</p>
                        <p className="text-sm font-medium text-gray-200">
                          {currentUser.displayName || currentUser.email}
                        </p>
                      </div>
                      <Link
                        to="/profile"
                        onClick={() => setIsMenuOpen(false)}
                        className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:bg-gray-700/30 rounded-lg transition-colors"
                      >
                        <Settings className="w-4 h-4" />
                        Profile Settings
                      </Link>
                      {location.pathname !== '/dashboard' && (
                        <button
                          onClick={handleDashboardClick}
                          className="w-full px-4 py-2 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors"
                        >
                          Go to Dashboard
                        </button>
                      )}
                      <button
                        onClick={handleLogout}
                        className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-red-500/10 text-red-400 text-sm font-medium rounded-lg hover:bg-red-500/20 transition-colors"
                      >
                        <LogOut className="w-4 h-4" />
                        Sign Out
                      </button>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        onClick={() => setIsMenuOpen(false)}
                        className="w-full px-4 py-2 text-center text-sm font-medium text-gray-300 hover:text-white transition-colors"
                      >
                        Sign In
                      </Link>
                      <Link
                        to="/signup"
                        onClick={() => setIsMenuOpen(false)}
                        className="w-full px-4 py-2 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors text-center"
                      >
                        Get Started
                      </Link>
                    </>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </header>
  );
}