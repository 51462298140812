import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FileSpreadsheet } from 'lucide-react';
import { toast } from 'sonner';
import { useAuth } from '../../contexts/AuthContext';
import DataUploader from './DataUploader';
import DataPreview from './DataPreview';
import DataVisualization from './DataVisualization';
import CollaborativeProject from './CollaborativeProject';
import { analyzeData } from '../../lib/analysis';
import { saveAnalysisProject, deleteAnalysisProject } from '../../lib/firebase';
import type { DataAnalysis } from './types';

export default function DataAnalysisPanel() {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [analysis, setAnalysis] = useState<DataAnalysis | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [activeTab, setActiveTab] = useState<'preview' | 'visualize'>('preview');
  const [projectId, setProjectId] = useState<string | null>(null);
  const [isSaved, setIsSaved] = useState(false);

  const handleDataUpload = (parsedData: any[], headers: string[]) => {
    setData(parsedData);
    setColumns(headers);
    setAnalysis(null);
    setIsSaved(false);
    setProjectId(null);
  };

  const handleAnalyze = async () => {
    if (!data.length) {
      toast.error('Please upload data first');
      return;
    }

    setIsAnalyzing(true);
    try {
      const result = await analyzeData(data, columns);
      setAnalysis(result);
      setIsSaved(false);
      setProjectId(null);
      toast.success('Analysis completed successfully');
    } catch (error) {
      console.error('Analysis error:', error);
      toast.error('Failed to analyze data');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleSave = async () => {
    if (!currentUser || !analysis) return;

    try {
      const id = await saveAnalysisProject(currentUser.uid, {
        id: projectId || Date.now().toString(),
        data,
        columns,
        analysis,
        timestamp: Date.now()
      });
      setProjectId(id);
      setIsSaved(true);
      toast.success('Project saved successfully');
    } catch (error) {
      console.error('Failed to save project:', error);
      toast.error('Failed to save project');
    }
  };

  const handleClear = async () => {
    if (projectId && currentUser) {
      try {
        await deleteAnalysisProject(currentUser.uid, projectId);
      } catch (error) {
        console.error('Failed to delete project:', error);
      }
    }
    setData([]);
    setColumns([]);
    setAnalysis(null);
    setIsSaved(false);
    setProjectId(null);
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 border border-gray-700/50">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-indigo-500/20 rounded-lg">
          <FileSpreadsheet className="w-5 h-5 text-indigo-400" />
        </div>
        <h2 className="text-xl font-semibold text-gray-200">Data Analysis</h2>
      </div>

      {!data.length ? (
        <DataUploader onUpload={handleDataUpload} />
      ) : (
        <div className="space-y-6">
          <div className="flex flex-wrap gap-4">
            <button
              onClick={() => setActiveTab('preview')}
              className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                activeTab === 'preview'
                  ? 'bg-indigo-500 text-white'
                  : 'bg-gray-700/30 text-gray-300 hover:bg-gray-700/50'
              }`}
            >
              Data Preview
            </button>
            <button
              onClick={() => setActiveTab('visualize')}
              className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                activeTab === 'visualize'
                  ? 'bg-indigo-500 text-white'
                  : 'bg-gray-700/30 text-gray-300 hover:bg-gray-700/50'
              }`}
            >
              Visualize
            </button>
            <div className="flex-1" />
            <button
              onClick={handleAnalyze}
              disabled={isAnalyzing}
              className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center gap-2 hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isAnalyzing ? 'Analyzing...' : 'Analyze'}
            </button>
            <button
              onClick={handleClear}
              className="px-4 py-2 bg-red-500/10 text-red-400 rounded-lg flex items-center gap-2 hover:bg-red-500/20"
            >
              Clear
            </button>
          </div>

          <div className="bg-gray-900/50 rounded-lg border border-gray-700/50">
            {activeTab === 'preview' ? (
              <DataPreview data={data} columns={columns} analysis={analysis} />
            ) : (
              <DataVisualization data={data} columns={columns} analysis={analysis} />
            )}
          </div>

          <CollaborativeProject
            data={data}
            columns={columns}
            analysis={analysis}
            onSave={currentUser ? handleSave : undefined}
            isSaved={isSaved}
          />
        </div>
      )}
    </div>
  );
}