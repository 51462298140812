import React, { useState, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { BarChart2, LineChart as LineChartIcon, PieChart as PieChartIcon } from 'lucide-react';
import type { DataAnalysis } from './types';

interface DataVisualizationProps {
  data: any[];
  columns: string[];
  analysis: DataAnalysis | null;
}

const COLORS = ['#6366f1', '#8b5cf6', '#d946ef', '#f43f5e', '#f97316', '#eab308'];

export default function DataVisualization({ data, columns, analysis }: DataVisualizationProps) {
  const [selectedChart, setSelectedChart] = useState<'bar' | 'line' | 'pie'>('bar');
  const [selectedColumn, setSelectedColumn] = useState(columns[0]);

  const chartData = useMemo(() => {
    if (!selectedColumn) return [];

    const isNumeric = data.every(row => !isNaN(row[selectedColumn]));
    
    if (isNumeric) {
      return data.map((row, index) => ({
        name: index + 1,
        value: Number(row[selectedColumn])
      }));
    } else {
      const grouped = data.reduce((acc: any, row) => {
        const value = row[selectedColumn];
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});

      return Object.entries(grouped).map(([name, value]) => ({
        name,
        value: value as number
      }));
    }
  }, [data, selectedColumn]);

  return (
    <div className="p-4">
      <div className="flex flex-wrap gap-4 mb-6">
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          className="px-3 py-2 bg-gray-700/30 border border-gray-600/50 rounded-lg text-gray-200"
        >
          {columns.map(column => (
            <option key={column} value={column}>
              {column}
            </option>
          ))}
        </select>

        <div className="flex gap-2">
          <button
            onClick={() => setSelectedChart('bar')}
            className={`p-2 rounded-lg ${
              selectedChart === 'bar'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-700/30 text-gray-300 hover:bg-gray-700/50'
            }`}
          >
            <BarChart2 className="w-5 h-5" />
          </button>
          <button
            onClick={() => setSelectedChart('line')}
            className={`p-2 rounded-lg ${
              selectedChart === 'line'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-700/30 text-gray-300 hover:bg-gray-700/50'
            }`}
          >
            <LineChartIcon className="w-5 h-5" />
          </button>
          <button
            onClick={() => setSelectedChart('pie')}
            className={`p-2 rounded-lg ${
              selectedChart === 'pie'
                ? 'bg-indigo-500 text-white'
                : 'bg-gray-700/30 text-gray-300 hover:bg-gray-700/50'
            }`}
          >
            <PieChartIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="h-[400px] w-full">
        <ResponsiveContainer>
          {selectedChart === 'bar' ? (
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '0.5rem',
                }}
              />
              <Bar dataKey="value" fill="#6366f1" />
            </BarChart>
          ) : selectedChart === 'line' ? (
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="name" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '0.5rem',
                }}
              />
              <Line type="monotone" dataKey="value" stroke="#6366f1" />
            </LineChart>
          ) : (
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                label
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '0.5rem',
                }}
              />
            </PieChart>
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
}