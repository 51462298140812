import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Share2, Printer, Copy, Check, Trash2, Save } from 'lucide-react';
import { toast } from 'sonner';
import { StudyPlan } from './types';
import { shareContent, printContent, copyToClipboard } from '../../utils/sharing';

interface StudyPlanDisplayProps {
  plan: StudyPlan;
  subject: string;
  onDelete: () => void;
  onSave?: () => void;
  isSaved?: boolean;
}

export default function StudyPlanDisplay({ 
  plan, 
  subject, 
  onDelete,
  onSave,
  isSaved 
}: StudyPlanDisplayProps) {
  const [isCopied, setIsCopied] = React.useState(false);

  const formatContent = () => {
    return `Study Plan for ${subject}\n\n${plan.overview}\n\n` +
      plan.schedule.map((day, index) => 
        `Day ${index + 1}:\n${day.activities.map(activity => `- ${activity}`).join('\n')}`
      ).join('\n\n');
  };

  const handleShare = async () => {
    try {
      const content = formatContent();
      const success = await shareContent(content, 'Study Plan');
      if (success) {
        toast.success('Study plan shared successfully');
      } else {
        const copied = await copyToClipboard(content);
        if (copied) {
          toast.success('Study plan copied to clipboard');
        } else {
          toast.error('Unable to share study plan');
        }
      }
    } catch (error) {
      toast.error('Failed to share study plan');
    }
  };

  const handlePrint = () => {
    try {
      printContent(formatContent());
      toast.success('Preparing to print study plan...');
    } catch (error) {
      toast.error('Failed to print study plan');
    }
  };

  const handleCopy = async () => {
    try {
      const success = await copyToClipboard(formatContent());
      if (success) {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
        toast.success('Study plan copied to clipboard');
      } else {
        toast.error('Failed to copy study plan');
      }
    } catch (error) {
      toast.error('Failed to copy study plan');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-8"
    >
      <div className="p-4 bg-indigo-500/10 border border-indigo-500/20 rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-indigo-400">
            Your Personalized Study Plan
          </h3>
          <div className="flex items-center gap-2">
            {onSave && (
              <button
                onClick={onSave}
                className={`p-2 ${
                  isSaved 
                    ? 'text-green-400 hover:text-green-300' 
                    : 'text-gray-400 hover:text-indigo-400'
                } transition-colors rounded-lg hover:bg-gray-700/30`}
                title={isSaved ? 'Saved' : 'Save plan'}
              >
                <Save className="w-4 h-4" />
              </button>
            )}
            <button
              onClick={handleShare}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Share plan"
            >
              <Share2 className="w-4 h-4" />
            </button>
            <button
              onClick={handlePrint}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Print plan"
            >
              <Printer className="w-4 h-4" />
            </button>
            <button
              onClick={handleCopy}
              className="p-2 text-gray-400 hover:text-indigo-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Copy plan"
            >
              {isCopied ? (
                <Check className="w-4 h-4 text-green-400" />
              ) : (
                <Copy className="w-4 h-4" />
              )}
            </button>
            <button
              onClick={onDelete}
              className="p-2 text-gray-400 hover:text-red-400 transition-colors rounded-lg hover:bg-gray-700/30"
              title="Delete plan"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </div>
        
        <p className="text-gray-300 mb-4">{plan.overview}</p>
        
        <div className="space-y-4">
          {plan.schedule.map((day, index) => (
            <div
              key={index}
              className="p-4 bg-gray-700/30 rounded-lg"
            >
              <h4 className="text-sm font-medium text-gray-200 mb-2">
                Day {index + 1}
              </h4>
              <ul className="space-y-2">
                {day.activities.map((activity, actIndex) => (
                  <li
                    key={actIndex}
                    className="flex items-start gap-2 text-gray-300"
                  >
                    <BookOpen className="w-4 h-4 mt-1 flex-shrink-0" />
                    <span>{activity}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}